import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { Controller, useForm } from "react-hook-form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import { useDispatch } from "react-redux";
import { getPaymentStatus } from "redux/actions";
import Config from "../../../configs/config.json";

const SetupPaymentModal = ({
  isOpen,
  toggle,
  type,
  paymentAdded,
  paymentDetails,
  getCardDetails,
  setPaymentPopup,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, formState } = useForm({ mode: "onChange" });

  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [stateName, setStateName] = useState("");

  // const stripe = useStripe();
  // const elements = useElements();

  // const CARD_ELEMENT_OPTIONS = {
  //     style: {
  //         base: {
  //             fontSize: '16px',
  //             color: '#424770',
  //             '::placeholder': {
  //                 color: '#d8d4d4',
  //             },
  //             lineHeight: '50px'
  //         },
  //         invalid: {
  //             color: '#fa755a',
  //             iconColor: '#fa755a',
  //         },
  //         hidePostalCode: true
  //     }
  // };

  const statesArray = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];

  const onSubmit = async (data) => {
    console.log(data);

    // if (stateName === '' || stateName === null) {
    //     openNotification('error', 'Oops!', 'Select State Name')
    //     return
    // }

    // setDisableBtn(true)

    // if (!stripe || !elements) {
    //     return;
    // }

    // if (paymentAdded) {     // Call API to update payment method
    //     const cardElement = elements.getElement(CardElement);

    //     const { error, paymentMethod } = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card: cardElement,
    //         billing_details: {
    //             name: data.name,
    //             email: data.email,
    //             address: {
    //                 line1: data.address1,
    //                 line2: data.address2 ? data.address2 : '',
    //                 city: data.city,
    //                 state: stateName,
    //                 postal_code: data.postalCode,
    //                 country: data.country.value
    //             }
    //         }
    //     });

    //     const params = new FormData()
    //     params.append('name', data.name)
    //     params.append('email', data.email)
    //     params.append('payment_method_id', paymentMethod.id)

    //     setLoading(true)

    //     Service.post({
    //         url: `/handle-subscription/update-payment-method/`,
    //         body: params,
    //         formdata: true
    //     }).then(response => {
    //         if (response && response.status === true) {
    //             toggle()
    //             setDisableBtn(false)
    //         }
    //         setLoading(false)
    //     }).catch(err => {
    //         console.log(err)
    //         openNotification('error', 'Oops!', 'Something went wrong. Please try later!')
    //         setLoading(false)
    //     })
    //     setLoading(true)

    //     const { id, ...otherFields } = paymentMethod;

    //     const cardData = {
    //         ...otherFields,
    //         stripe_payment_method_id: paymentMethod.id
    //     }

    //     Service.put({
    //         url: `/card-details/${paymentDetails && paymentDetails.id}/`,
    //         body: JSON.stringify(cardData, null, 2)
    //     }).then(response => {
    //         if (response && response.status === true) {
    //             openNotification('success', 'Success!', 'Payment method updated successfully!')
    //             toggle()
    //             getCardDetails()
    //         }
    //         setLoading(false)
    //     }).catch(err => {
    //         console.log(err)
    //         setLoading(false)
    //     })
    // } else {         // Call API to add payment method
    //     const cardElement = elements.getElement(CardElement);

    //     const { error, paymentMethod } = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card: cardElement,
    //         billing_details: {
    //             name: data.name,
    //             email: data.email,
    //             address: {
    //                 line1: data.address1,
    //                 line2: data.address2 ? data.address2 : '',
    //                 city: data.city,
    //                 state: stateName,
    //                 postal_code: data.postalCode,
    //                 country: data.country.value
    //             }
    //         }
    //     });

    //     const params = new FormData()
    //     params.append('name', data.name)
    //     params.append('email', data.email)
    //     params.append('payment_method_id', paymentMethod.id)

    //     setLoading(true)

    //     Service.post({
    //         url: `/handle-subscription/`,
    //         body: params,
    //         formdata: true
    //     }).then(response => {
    //         if (response && response.status === true) {
    //             openNotification('success', 'Success!', 'Payment method added successfully!')
    //             toggle()
    //             dispatch(getPaymentStatus(true))
    //             setDisableBtn(false)
    //             history.push('/billing/overview')
    //         }
    //         setLoading(false)
    //     }).catch(err => {
    //         console.log(err)
    //         openNotification('error', 'Oops!', 'Something went wrong. Please try later!')
    //         setLoading(false)
    //     })

    //     Service.post({
    //         url: `/card-details/`,
    //         body: JSON.stringify(paymentMethod)
    //     }).then(response => {
    //         console.log(response)
    //     }).catch(err => {
    //         console.log(err)
    //     })

    // };
  };

  const handleToggle = () => {
    toggle();
    if (type === "expiry") {
      setPaymentPopup(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      backdrop={true}
      toggle={toggle}
      size="md"
      className="modal-dialog-centered payment-modal"
    >
      <ModalHeader className="bg-header">
        <div>
          <img
            src={require("../../../assets/img/Priya_AI/logo-black.png")}
            className="img-fluid logo-mobile"
            width={100}
          />
          <span className="ml-3 ff-inter-semi text-14 text-uppercase">
            Setup Payment Method
          </span>
        </div>
        <div className="cursor-pointer" onClick={handleToggle}>
          <AiOutlineClose color="#000" size="15" />
        </div>
      </ModalHeader>
      <ModalBody className="my-1 px-3">
        <div className="position-rel">
          <div className={`${loading ? "bg-hide" : ""}`}>
            <h3 className="ff-inter-semi text-dark text-xxl mb-2">
              Pay as you go
            </h3>
            <p className="ff-inter-medium text-gray text-16">
              A temporary authorization hold will be placed on your card for $5.
              At the end of each calendar month, you’ll be charged for all usage
              that happened during the month.
            </p>
            <a
              href={`${Config.HOME_URL}/pricing`}
              target={"_blank"}
              className="ff-inter-semi mt-3 text-blue cursor-pointer"
            >
              Learn more about pricing
            </a>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <Label className="text-darkgray ff-inter-semi text-17">
                  Card Information
                </Label>

                {/* <div className="stripe-card-section">
                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                </div> */}
                <FormGroup className="mt-2">
                  <Controller
                    id="name"
                    name="name"
                    rules={{
                      required: "Please enter the name",
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.state && true}
                        placeholder="Name"
                        name="name"
                        type="text"
                        {...field}
                        className="mt-1"
                      />
                    )}
                  />
                  {formState.errors.state && (
                    <FormFeedback>
                      {formState.errors &&
                        formState.errors.name &&
                        formState.errors.name.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup className="mt-1">
                  <Controller
                    id="email"
                    name="email"
                    rules={{
                      required: "Please enter the email",
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.state && true}
                        placeholder="Email"
                        name="email"
                        type="email"
                        {...field}
                        className="mt-1"
                      />
                    )}
                  />
                  {formState.errors.state && (
                    <FormFeedback>
                      {formState.errors &&
                        formState.errors.email &&
                        formState.errors.email.message}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>
              <div className="mt-2">
                <Label className="text-darkgray ff-inter-semi text-17">
                  Billing Address
                </Label>
                <FormGroup>
                  <Controller
                    id="country"
                    name="country"
                    control={control}
                    defaultValue={{ value: "US", label: "United States" }}
                    render={({ field }) => (
                      <Select
                        invalid={formState.errors.country && true}
                        placeholder="Country"
                        classNamePrefix="react-select"
                        options={[{ value: "US", label: "United States" }]}
                        {...field}
                      />
                    )}
                  />
                  {formState.errors.country && (
                    <FormFeedback>
                      {formState.errors &&
                        formState.errors.country &&
                        formState.errors.country.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Controller
                    id="address1"
                    name="address1"
                    rules={{
                      required: "Please enter the address line 1",
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.address1 && true}
                        placeholder="Address line 1"
                        name="address1"
                        type="text"
                        className="mt-1"
                        {...field}
                      />
                    )}
                  />
                  {formState.errors.address1 && (
                    <FormFeedback>
                      {formState.errors &&
                        formState.errors.address1 &&
                        formState.errors.address1.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Controller
                    id="address2"
                    name="address2"
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.address2 && true}
                        placeholder="Address line 2"
                        name="address2"
                        type="text"
                        {...field}
                        className="mt-1"
                      />
                    )}
                  />
                </FormGroup>

                <div className="d-flex">
                  <FormGroup>
                    <Controller
                      id="city"
                      name="city"
                      rules={{
                        required: " Please enter the city",
                      }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          invalid={formState.errors.city && true}
                          placeholder="City"
                          name="city"
                          type="text"
                          {...field}
                          className="mt-1 mr-1"
                        />
                      )}
                    />
                    {formState.errors.city && (
                      <FormFeedback>
                        {formState.errors &&
                          formState.errors.city &&
                          formState.errors.city.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      id="postalCode"
                      name="postalCode"
                      rules={{
                        required: "Please enter the postal code",
                      }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          invalid={formState.errors.postalCode && true}
                          placeholder="Postal Code"
                          name="postalCode"
                          type="text"
                          {...field}
                          className="mt-1 ml-1"
                        />
                      )}
                    />
                    {formState.errors.postalCode && (
                      <FormFeedback>
                        {formState.errors &&
                          formState.errors.postalCode &&
                          formState.errors.postalCode.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </div>
                <FormGroup>
                  <Select
                    invalid={formState.errors.country && true}
                    placeholder="State"
                    onChange={(option) => setStateName(option.value)}
                    classNamePrefix="react-select"
                    options={statesArray}
                  />
                </FormGroup>

                {type && (
                  <div className="mt-4">
                    <Label className="text-darkgray ff-inter-semi text-17 mb-0">
                      Primary Business Address
                    </Label>
                    <p className="ff-inter-medium text-gray text-16">
                      This is the physical address of company purchasing PRIYA
                      services and is used to calculate any applicable sales
                      taxes.
                    </p>

                    <label className="main text-17 ff-inter-semi text-gray pr-1">
                      Same as billing address
                      <input type="checkbox" />
                      <span className="geekmark"></span>
                    </label>
                    <div className="mt-4">
                      <Label className="text-darkgray ff-inter-semi text-17 mb-0">
                        Business tax ID
                      </Label>
                      <p className="ff-inter-medium text-gray text-16">
                        If you are a business tax registrant, please enter your
                        business tax ID here
                      </p>
                    </div>
                    <Row className="d-flex">
                      <Col xs="6" sm="5" className="pr-0">
                        <Select
                          placeholder="Select Type"
                          classNamePrefix="react-select"
                        />
                      </Col>
                      <Col xs="6" sm="7">
                        <Input />
                      </Col>
                    </Row>
                  </div>
                )}

                <div className="d-flex flex-column flex-sm-row align-items-center mt-4">
                  {/* <button
                                        className={`text-16 border-20 button-gradient ff-inter-semi mr-2 px-5 py-1 text-gray ${disableBtn ? 'pointer-none' : 'cursor-pointer '}`}
                                        type="submit"
                                        disabled={!stripe}
                                        style={{ maxHeight: '38px' }}
                                    >
                                        Setup Payment Method
                                    </button> */}
                  <button
                    className="text-16 ff-inter-semi btn-width text-gray btn-cancel px-5 py-1 mt-4 mt-sm-0"
                    onClick={handleToggle}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </div>
          {loading && (
            <div className="position-loader">
              <Loader />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SetupPaymentModal;
