import React, { useState } from 'react'
import StepOne from "../StepOne/index";
import StepTwo from "../StepTwo/index";
import { Service } from 'services/Service';
import { openNotification } from 'components/Helper';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import { Helmet } from "react-helmet";

const IntegrationModal = () => {

    const [page, setPage] = useState("pageone")

    //Step 1 
    const [selectStore, setSelectStore] = useState(null)
    const [hoverEffectStepOne, setHoverEffectStepOne] = useState(null)
    const [clickEffectStepOne, setClickEffectStepOne] = useState(null)

    //Step 2
    const [buttonDisable, setButtonDisable] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const nextPage = (page) => {
        setPage(page)
    }
    const handleVerify = (domain, setLoading, toggle) => {
        setLoading(true)

        const urlRegex = /^(http:\/\/|https:\/\/)/;

        if (urlRegex.test(domain)) {
            openNotification('error', 'Oops!', 'Store URL should not start with http:// or https://')
            return false
        }
        Service.get({
            url: `/shopify/auth/?shop=${domain}&redirect=false`
        })
            .then(response => {
                if (response && response.status === false) {
                    toggle()
                    setLoading(false)
                    // nextPage('pagetwo')
                    setErrorMessage(response.message)
                    return
                } else {
                    toggle()
                    setLoading(false)
                    window.open(response.url, '_self');
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })

    }


    return (
        <div className='bg-dark '>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Installation</title>
            </Helmet>
            <div className={`integration-modal ${page === "pagetwo" ? 'bg-integration' : 'bg-left-auth '}`}>
                <section className='py-0 nav-section'>
                    <IndexNavbar />
                </section>
                <div className='pb-3'>
                    <div className={`mt-lg-5 d-flex flex-column justify-content-between  ${page === "pageone" ? 'align-items-center' : ''}`} >

                        {
                            {
                                pageone: <StepOne setSelectStore={setSelectStore} hoverEffectStepOne={hoverEffectStepOne} setHoverEffectStepOne={setHoverEffectStepOne} clickEffectStepOne={clickEffectStepOne} setClickEffectStepOne={setClickEffectStepOne} onClickVerify={handleVerify} errorMessage={errorMessage} />,
                                pagetwo: <StepTwo errorMessage={errorMessage} nextPage={nextPage} />
                            }[page]
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntegrationModal

