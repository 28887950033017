import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import secureLocalStorage from "react-secure-storage";

export const getStarted = (openStatus) => {
  return (dispatch) => {
    dispatch({ type: "GET_STARTED", payload: openStatus });
  };
};

export const getProductList = (params, openStatus) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_PRODUCT_LIST",
      payload: [],
      isFetching: true,
      isLoading: params.loading || false,
    });
    // First Register Shows if User Signup For First Time.
    let first_register = secureLocalStorage.getItem("first_register");
    if(first_register){
      secureLocalStorage.setItem("first_register",false)
    }
    await Service.get({
      url: `/products/sync_products/?signup=${first_register}`,
      formdata: true,
    }).then(async (response) => {
      if (response) {
       
          await Service.get({
            url: `/products/?limit=${params.limit}&page=${params.page}&keyword=${params.keyword}`,
            formdata: true,
          }).then((response) => {
            if (response) {
              if (response && response.status) {
                dispatch({
                  type: "GET_PRODUCT_LIST",
                  payload: response,
                  isFetching: false,
                  isLoading: false,
                });
                if (openStatus) {
                  Service.post({
                    url: `/update-user-products/`,
                    formdata: true,
                  })
                    .then((response) => {})
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            }
          });
        
      }
    });
  };
};

export const getPaymentAddedStatus = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_USER_DETAILS", payload: [], isFetching: true });

    await Service.post({
      url: `/get-user-details/`,
      formdata: true,
    }).then((response) => {
      if (response) {
        if (response && response.status) {
          dispatch({
            type: "GET_USER_DETAILS",
            payload: response,
            isFetching: false,
          });
        }
      }
    });
  };
};

export const getPaymentStatus = (bool) => {
  return { type: "PAYMENT_STATUS", payload: bool };
};
