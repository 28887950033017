const initialState = {
    productList: [],
    userDetails: {},
    isFetchProduct: false,
    isFetchUserList: false,
    isPageLoad: false,
    initialDashboard: false,
    paymentStatus: false
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STARTED':
            return {
                ...state,
                initialDashboard: action.payload
            };
        case 'GET_PRODUCT_LIST':
            return {
                ...state,
                productList: action.payload,
                isFetchProduct: action.isFetching,
                isPageLoad: action.isLoading
            };
        case 'GET_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload,
                isFetchUserList: action.isFetching
            };
        case 'PAYMENT_STATUS':
            return { ...state, paymentStatus: action.payload }
        default:
            return state;
    }
};

export default modalReducer;