import { MenuItems } from 'components/MenuItems'
import React, { useEffect } from 'react'
import { Menu } from 'react-feather'
import { MdOutlineNotifications } from 'react-icons/md'
import secureLocalStorage from "react-secure-storage";
import { Service } from 'services/Service';
import { openNotification } from 'components/Helper';

const Navbar = () => {
    const email = secureLocalStorage.getItem('email')
    const loginTimestamp = secureLocalStorage.getItem('Expired');

    const tenMinutesInSeconds = 10 * 60; // 10 minutes in seconds
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Current time in seconds

    // Parse the loginTimestamp as a Data
    const loginTime = new Date(loginTimestamp);

    // Calculate the time difference
    const timeDifferenceInSeconds = (loginTime.getTime() - currentTimeInSeconds * 1000) / 1000;

    useEffect(() => {

        if (loginTimestamp) {

            // If the remaining time is less than 10 minutes, call the API
            if (timeDifferenceInSeconds < tenMinutesInSeconds) {

                const params = new FormData()
                params.append('refresh_token', secureLocalStorage.getItem('refreshToken'))

                Service.post({
                    url: '/refresh-access-token/',
                    body: params,
                    formdata: true
                })
                    .then((response) => {
                        const currentTime = new Date()
                        const twoHoursFromNow = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
                        secureLocalStorage.setItem('Expired', twoHoursFromNow.toString());
                        secureLocalStorage.setItem('token', response && response.access_token); 
                        secureLocalStorage.setItem(
                            "email",
                            response && response.email
                        );
                        secureLocalStorage.setItem("is_loggedin", "done");
                        secureLocalStorage.setItem("integration_process", "completed");
                    }
                    )
                    .catch(err => {
                        console.log(err)
                        openNotification('error', 'Oops!', 'Something went wrong. Please try later!')
                    })
            }
        }
    }, [timeDifferenceInSeconds])


    return (
        <div className='d-flex justify-content-between border-bottom px-3 py-2 p-sm-3 project-section'>
            <div className='d-flex flex-row align-items-center'>
                <img src={require('../../assets/img/Priya_AI/logo-black.png')} className="img-fluid logo-mobile" width={145} />
                <p className='d-none d-sm-block ml-sm-3 ff-inter-medium text-gray text-xl '>{(window.location.href.indexOf('/billing')) > -1 ? 'Billing' : (window.location.href.indexOf('/settings')) > -1 ? 'Settings' : (window.location.href.indexOf('/logout')) > -1 ? 'Logout' : 'Dashboard'}</p>
            </div>
            <div className="cursor-pointer d-flex align-items-center">
                <div className='border-right pr-4 position-rel'>
                    <MdOutlineNotifications color="#999999" size={25} />
                </div>
                <p className='d-sm-none email-text mb-0 mx-2 mx-sm-3 ff-inter-medium text-gray'>{email && `${email.slice(0, 13)}...`}</p>
                <p className='d-none d-sm-block email-text mb-0 mx-2 mx-sm-3 ff-inter-medium text-gray'>{email}</p>
                <div className='d-none'>
                    <Menu />
                </div>
                <div className='d-block d-xl-none'>
                    <MenuItems />
                </div>
            </div>
        </div>
    )
}

export default Navbar