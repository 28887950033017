import React from "react";

import Navbar from "components/NavbarDashboard";
import BillingOptions from "./BillingOptions";
import { Helmet } from "react-helmet";
const Billing = () => {
  return (
    <div className="bg-white dashboard-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Billing</title>
      </Helmet>
      <Navbar />

      <div className="position-loading d-flex flex-column align-items-center justify-content-center mt-5">
        {/* <Loader />{" "} */}
        {/* <span className="mt-2 ff-inter-medium text-dark text-18 ml-3">
          Setting up your Dashboard...
        </span> */}
      </div>
      <BillingOptions billingPop={true} />
    </div>
  );
};

export default Billing;
