
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientSVG";
import RadialSeparators from "./RadialGradient";

export default function PieGapChart({ status }) {


    return (
        <div className="App">
            <div style={{ height: "150px", width: "150px" }}>
                <GradientSVG status={status} />
                <CircularProgressbarWithChildren
                    value={`${status === 'Learning' || status === null ? 33 : status === 'Evaluating' ? 66 : 99}`}
                    strokeWidth={18}
                    styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: `url(#${status})`,
                        textColor: "gray",
                        textSize: "16px",
                        rotation: 0.5 + (1 - 33 / 100) / (`${status === 'Learning' || status === null ? 150 : status === 'Evaluating' ? 100 : 1}`)
                    })}
                >
                    <RadialSeparators
                        count={6}
                        style={{
                            background: "#fff",
                            width: "2px",
                            // This needs to be equal to props.strokeWidth
                            height: `${18}%`
                        }}
                    />
                </CircularProgressbarWithChildren>
            </div>
        </div>
    );
}


