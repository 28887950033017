
function GradientSVG({ status }) {

    const idCSS = "hello";
    const gradientTransform = `rotate(90)`;
    return (
        // <svg style={{ height: 0, width: '100%' }}>
        //     <defs>
        //         <linearGradient id={idCSS} gradientTransform={gradientTransform}>
        //             <stop offset="5%" stopColor={`${status === 'Learning' || status === null ? '#ffa800' : status === 'Evaluating' ? '#fdd800' : '#66c10e'}`} />
        //         </linearGradient>
        //     </defs>
        // </svg>
        <svg style={{ height: 0, width: 0, position: 'absolute' }} aria-hidden="true">
            <defs>
                <linearGradient id={`${(status === null) ? 'null' : 'Learning'}`} gradientTransform={gradientTransform}>
                    <stop offset="5%" stopColor="#ffa800" />
                </linearGradient>
                <linearGradient id={`${(status === 'Evaluating') ? 'Evaluating' : 'Evaluating'}`} gradientTransform={gradientTransform}>
                    <stop offset="70%" stopColor="#ffa800" />
                    <stop offset="80%" stopColor="#fdd800" />
                </linearGradient>
                <linearGradient id={`${(status === 'Optimizing') ? 'Optimizing' : 'Optimizing'}`} gradientTransform="rotate(-20)">
                    <stop offset="0%" stopColor="#ffa800" />
                    <stop offset="40%" stopColor="#fdd800" />
                    <stop offset="80%" stopColor="#32cd32" />
                </linearGradient>
            </defs>
        </svg>

    );
}

export default GradientSVG;
