import React, { useState } from "react";
import { Row, Col, Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import "moment-timezone/builds/moment-timezone-with-data";
import Config from "../../../configs/config.json";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);

  const { control, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  //Forgot Password API call
  const onSubmit = (data) => {
    const params = new FormData();
    params.append("email", data.loginEmail);

    setButtonDisable(true);

    Service.post({
      url: "/password-reset-request/",
      body: params,
      formdata: true,
    })
      .then((response) => {
        setButtonDisable(false);
        if (response && response.status === true) {
          setFormVisible(false);
          setSuccessMessage(true);
        } else {
          openNotification("error", "Oops!", response && response.message);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonDisable(false);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  return (
    <div className="bg-dark login-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Login</title>
      </Helmet>

      {formVisible && (
        <div className="bg-left-auth d-flex flex-column align-items-center justify-content-between">
          <div className="pt-5">
            <a href={`${Config.HOME_URL}`}>
              <img
                src={require("../../../assets/img/Priya_AI/logo.png")}
                className="img-fluid logo-beta"
                width="155px"
              />
            </a>
          </div>
          <Row className="w-100">
            <Col lg="4"></Col>
            <Col lg="4">
              <h2 className="text-white text-center ff-light mb-4">
                Forgot Password
              </h2>
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className="login-modal d-flex flex-column align-items-center justify-content-center"
              >
                <FormGroup className="">
                  <Controller
                    id="loginEmail"
                    name="loginEmail"
                    type="email"
                    rules={{
                      required: "Please enter your email",
                    }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.loginEmail && true}
                        placeholder="Email"
                        {...field}
                        className="py-4"
                      />
                    )}
                  />
                  {formState.errors.loginEmail && (
                    <FormFeedback>
                      {formState.errors.loginEmail.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <div className="mt-2 text-center">
                  <button
                    type="submit"
                    className={`sign-button btn-white d-flex align-items-center justify-content-center text-dark mt-3 ff-bold ${
                      buttonDisable ? "py-2" : "py-3"
                    }`}
                  >
                    <span className="ff-bold">
                      {buttonDisable ? <Loader /> : "Submit"}
                    </span>
                  </button>
                </div>
                <p className="text-gray ff-light mt-4 text-center">
                  Back to
                  <span
                    className="text-banner cursor-pointer ml-2"
                    onClick={() => history.push("/login")}
                  >
                    Login
                  </span>
                </p>
              </Form>
            </Col>
            <Col lg="4"></Col>
          </Row>
          <div className="text-center">
            <footer className="ff-inter-regular position-abs3 text-gray">
              <a
                href={`${Config.HOME_URL}/privacypolicy`}
                target={"_blank"}
                className="text-gray"
              >
                Privacy Policy
              </a>{" "}
              <span className="px-2">|</span>{" "}
              <a
                href={`${Config.HOME_URL}/termsofuse`}
                target={"_blank"}
                className="text-gray"
              >
                Terms of Uses
              </a>
            </footer>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="bg-left-auth d-flex flex-column align-items-center justify-content-center">
          <Row className="w-100">
            <Col lg="3"></Col>
            <Col lg="6" className="text-center verification-screen">
              <img
                src={require("../../../assets/img/Dashboard/check-mark.png")}
                className="img-fluid check-mark"
              />
              <p className="text-gray font-style ff-light mt-4 text-center text-22">
                We sent you a reset password email.
              </p>
              <p className="text-gray font-style ff-light text-center text-22">
                Please click on the link in your email to reset your password.
              </p>
            </Col>
            <Col lg="3"></Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
