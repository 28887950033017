import Config from "../configs/config.json";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import secureLocalStorage from "react-secure-storage";

const openNotification = (type, message, description) => (
  <Toast>
    <ToastHeader icon={type}>{message}</ToastHeader>
    <ToastBody> {description} </ToastBody>
  </Toast>
);

const Storage = {
  isLogedin: (para) => {
    return secureLocalStorage.getItem("token") !== null;
  },
  set: (key, value) => {
    secureLocalStorage.setItem(key, JSON.stringify(value));
  },
  get: (key, value) => {
    return JSON.parse(secureLocalStorage.getItem(key));
  },
  setString: (key, value) => {
    secureLocalStorage.setItem(key, value);
  },
  logout: () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("email");
    secureLocalStorage.removeItem("user_id");
    secureLocalStorage.removeItem("integration_process");
    secureLocalStorage.removeItem("is_loggedin");
  },
  getToken: (key, value) => {
    return secureLocalStorage.getItem("token")
      ? secureLocalStorage.getItem("token")
      : false;
  },
};

const Service = {
  get: async (para) => {
    const header = {
      "content-type": "application/json",
      accept: "application/json",
    };
    const token = secureLocalStorage.getItem("token");
    if (token) {
      header["Authorization"] = `Bearer ${token}`;
    }
    return fetch(Config.BASE_URL + para.url, {
      method: "GET",
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout();
          window.location.href = "/";
        } else {
          return response.json();
        }
      },
      (error) => {
        console.log(error);
        if (
          error === "TypeError: NetworkError when attempting to fetch resource."
        ) {
          openNotification(
            "error",
            "Unable to reach server.",
            "Please check your network connectivity"
          );
        }
      }
    );
    //.then(response => response.json());
  },
  post: async (para) => {
    const header = {};
    if (para.formdata) {
      // header["content-type"] = "multipart/form-data"
    } else {
      header["content-type"] = "application/json";
      header["accept"] = "application/json";
    }
    const token = secureLocalStorage.getItem("token");
    if (token) {
      header["Authorization"] = `Bearer ${token}`;
    }

    return fetch(Config.BASE_URL + para.url, {
      method: "POST",
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout();
          window.location.href = "/";
        } else {
          return response.json();
        }
      },
      (error) => {
        console.log(error);
        if (
          error === "TypeError: NetworkError when attempting to fetch resource."
        ) {
          openNotification(
            "error",
            "Unable to reach server.",
            "Please check your network connectivity"
          );
        }
      }
    );
  },
  put: (para) => {
    const header = {};
    if (para.formdata) {
      // header["content-type"] = "multipart/form-data"
    } else {
      header["content-type"] = "application/json";
      header["accept"] = "application/json";
    }
    const token = secureLocalStorage.getItem("token");
    if (token) {
      header["Authorization"] = `Bearer ${token}`;
    }

    return fetch(Config.BASE_URL + para.url, {
      method: "PUT",
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout();
          window.location.href = "/";
        } else {
          return response.json();
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //.then(response => response.json())
  },
  patch: (para) => {
    const header = {};
    if (para.formdata) {
      // header["content-type"] = "multipart/form-data"
    } else {
      header["content-type"] = "application/json";
      header["accept"] = "application/json";
    }

    const token = secureLocalStorage.getItem("token");
    if (token) {
      header["Authorization"] = `Bearer ${token}`;
    }

    return fetch(Config.BASE_URL + para.url, {
      method: "PATCH",
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout();
          window.location.href = "/";
        } else {
          return response.json();
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //.then(response => response.json())
  },
  delete: (para) => {
    const header = {
      "content-type": "application/json",
      accept: "application/json",
    };

    const token = secureLocalStorage.getItem("token");
    if (token) {
      header["Authorization"] = `Bearer ${token}`;
    }
    return fetch(Config.BASE_URL + para.url, {
      method: "DELETE",
      headers: header,
      body: para.body,
    }).then(
      (response) => {
        if (response.status === 401 || response.status === 403) {
          //Unauthorized.  Invalid JWT Token
          Storage.logout();
          window.location.href = "/";
        } else {
          return response.json();
        }
      },
      (error) => {
        console.log(error);
        if (
          error === "TypeError: NetworkError when attempting to fetch resource."
        ) {
          openNotification(
            "error",
            "Unable to reach server.",
            "Please check your network connectivity"
          );
        }
      }
    );
    //.then(response => response.json());
  },
};

export { Service, Storage };
