import React, { useEffect, useState } from "react";
import { Service } from "services/Service";
import SetupPaymentModal from "views/priya_ai/Dashboad/SetupPaymentModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../configs/config.json";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(Config.STRIPE_KEY);

const PaymentNotification = () => {
  const { paymentStatus } = useSelector(({ modal }) => modal);

  const [freeAPICalls, setFreeAPICalls] = useState(null);
  const [paymentAdded, setPaymentAdded] = useState(null);
  const [openSetupModal, setOpenSetupModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);

  const toggleSetupModal = () => setOpenSetupModal(!openSetupModal);

  const getPaymentAddedStatus = () => {
    Service.post({
      url: `/get-user-details/`,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          setPaymentAdded(response.payment_method);
          setFreeAPICalls(
            response.remaining_api_credits &&
              response.remaining_api_credits
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPaymentAddedStatus();
  }, []);

  // Call this API when paymentStatus updated and it is true
  useEffect(() => {
    if (paymentStatus) {
      getPaymentAddedStatus();
    }
  }, [paymentStatus]);

  return (
    <Elements stripe={stripePromise}>
      {paymentAdded === false && (
        <div className="payment-method-status bg-dark p-2 text-center d-flex align-items-center justify-content-center">
          {freeAPICalls !== 0 && paymentAdded === false && (
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-start">
              <p className="text-white ff-inter-regular mb-0 ml-3 text-xl">
                You have {freeAPICalls} free API credits remaining. Please add a
                payment method to continue using PRIYA.
              </p>
              <button
                className="ff-inter-semi btn-white text-16 ml-lg-4 px-4 py-1 mt-4 mt-lg-0"
                onClick={toggleSetupModal}
              >
                Add payment method
              </button>
            </div>
          )}
          {freeAPICalls !== 0 && paymentAdded === true && (
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-start">
              <p className="text-white ff-inter-regular mb-0 ml-3 text-xl">
                You have {freeAPICalls} free API credits remaining
              </p>
            </div>
          )}
          {freeAPICalls === 0 && paymentAdded === false && (
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-start">
              <p className="text-white ff-inter-regular mb-0 ml-3 text-xl">
                Your free credits have expired. Please add a payment method to
                resume using PRIYA.
              </p>
              <button
                className="ff-inter-semi btn-white text-16 ml-lg-4 px-4 py-2 mt-3 mt-lg-0"
                onClick={toggleSetupModal}
              >
                Add payment method
              </button>
            </div>
          )}
        </div>
      )}

      <SetupPaymentModal
        isOpen={openSetupModal}
        toggle={toggleSetupModal}
        type={companyModal}
        paymentAdded={paymentAdded}
      />
    </Elements>
  );
};

export default PaymentNotification;
