import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  ModalHeader,
} from "reactstrap";
import Loader from "components/Loader";
import { FaShopify, FaTimes } from "react-icons/fa";
import { openNotification } from "components/Helper";
import { Service } from "services/Service";
import secureLocalStorage from "react-secure-storage";
import Config from "../../configs/config.json";
export const AuthorizeShopify = () => {
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [message, setMessage] = useState("Please enter your shop URL");
  const [value, setValue] = useState("");
  const [connectShopify, setConnectShopify] = useState(false);

  const toggle = () => setConnectShopify(!connectShopify);

  const handleChange = (e) => {
    setDomain(e.target.value + ".myshopify.com");
    setValue(e.target.value);
    setValidate(false);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    setLoading(true);

    const urlRegex = /^(http:\/\/|https:\/\/)/;

    if (urlRegex.test(domain)) {
      openNotification(
        "error",
        "Oops!",
        "Store URL should not start with http:// or https://"
      );
      return false;
    }
    Service.get({
      url: `/shopify/auth/?shop=${domain}&redirect=false`,
    })
      .then((response) => {
        if (response && response.status === false) {
          toggle();
          setLoading(false);
          return;
        } else {
          toggle();
          setLoading(false);
          window.open(response.url, "_self");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const user_logged = secureLocalStorage.getItem("is_loggedin");

  return (
    <>
      {user_logged !== "done" && (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn-transparent d-flex align-items-center justify-content-center py-3 px-3 text-white mt-3 ff-inter-semi"
            onClick={toggle}
            // href='https://apps.shopify.com/priya'
            // target='_blank'
          >
            <FaShopify color="white" size={18} />
            <span className="text-17 ff-inter-semi ml-2">
              Sign up with Shopify
            </span>
          </button>
          {/* <button
                    type="button"
                    className="btn-transparent d-flex align-items-center justify-content-center py-3 px-3 text-white mt-3 ff-inter-semi"
                    onClick={toggle}
                >
                    <FaShopify color='white' size={18} />
                    <span className="text-17 ff-inter-semi ml-2">Sign up with Shopify</span>
                </button>  */}
        </div>
      )}
      <Modal
        isOpen={connectShopify}
        toggle={toggle}
        backdrop={true}
        size="lg"
        className="modal-dialog-centered payment-popup shopify-popup"
      >
        <ModalHeader className="py-4" style={{ backgroundColor: "#f4f4f4" }}>
          <div className="text-center mt-3">
            <a href={`${Config.HOME_URL}/`} target="_blank">
              <img
                src={require("../../assets/img/Priya_AI/logo-black.png")}
                className="img-fluid ml-4 logo-shopify-mobile"
                width={145}
              />
            </a>
          </div>
          <div className="close-btn">
            <FaTimes size={15} onClick={toggle} className="cursor-pointer" />
          </div>
        </ModalHeader>
        <ModalBody
          className="p-2 p-sm-4"
          style={{ backgroundColor: "#f4f4f4" }}
        >
          <div className="position-rel">
            <div className={`${loading ? "bg-hide" : ""}`}>
              <form onSubmit={handleVerify} className="my-4 text-center">
                <div className="shopify-popup">
                  <FormGroup>
                    <h3 className="text-gray-shopify ff-inter-semi mb-0">
                      Enter Your Shopify Store Domain
                    </h3>
                    {/* <p className="text-gray ff-inter-semi text-16 mb-3">PRIYA is free to use up to 20,000 API calls, then <a className='text-blue' href={Config.HOME_URL + '/pricing'}>pay as you go.</a></p> */}
                    {/* <p className="text-gray ff-inter-semi text-16 mb-3">PRIYA is free to try, then pick a monthly plan.</p> */}
                    <p className="text-gray ff-inter-semi text-16 mb-3">
                      Start with a 7 day free trial.
                    </p>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <InputGroup>
                        <Input
                          required
                          type="text"
                          onChange={handleChange}
                          className="ff-inter-medium"
                        />
                        <InputGroupText className="ff-inter-medium">
                          .myshopify.com
                        </InputGroupText>
                      </InputGroup>
                      {validate && (
                        <>
                          <p className="text-red text-14 ff-inter-regular">
                            {message}
                          </p>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="mt-4">
                  <button
                    className={`text-blue text-16 btn-reconnect green-button ff-inter-semi py-2`}
                    type="submit"
                    // onClick={() => {
                    //     if (value === '') {
                    //         setValidate(true)
                    //         setMessage('Please enter your shop URL')
                    //     } else {
                    //         handleVerify()
                    //     }
                    // }
                    // }
                  >
                    Continue
                  </button>
                </div>
                <p className="text-gray ff-inter-regular text-center my-3">
                  By continuing, you agree to our{" "}
                  <a
                    href={`${Config.HOME_URL}/privacypolicy`}
                    target={"_blank"}
                    className="text-blue"
                  >
                    Privacy Policy
                  </a>
                </p>
              </form>
            </div>
            {loading && (
              <div className="position-loader">
                <Loader />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
