import React, { useState } from "react";
import { AnchorScroll } from "helper";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col,
} from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import secureLocalStorage from "react-secure-storage";
import Logout from "components/Logout";
import Config from "../../configs/config.json";

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };

  const onCollapseExited = () => {
    setCollapseOut("");
  };

  const user_logged = secureLocalStorage.getItem("is_loggedin");

  return (
    <>
      <Navbar expand="lg" className="bg-nav px-0">
        <div className="w-100">
          <Row className="d-flex align-items-center justify-content-center py-2">
            <Col lg="4" xl="3">
              <div className="d-flex justify-content-between">
                <NavbarBrand id="navbar-brand" onClick={() => AnchorScroll()}>
                  <a href={`${Config.HOME_URL}/`}>
                    <img
                      src={require("../../assets/img/Priya_AI/logo.png")}
                      alt="logo"
                      className="img-fluid logo-beta"
                      width={155}
                    />
                  </a>
                  <span className="ml-2 text-banner ff-inter-semi">
                    INSTALLATION
                  </span>
                </NavbarBrand>
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler navbar-toggler mt-0"
                  onClick={toggleCollapse}
                >
                  <FiMenu className="text-white" size="24" />
                </button>
              </div>
            </Col>
            <Col lg="6" xl="7" className="text-lg-right">
              <Collapse
                className={"justify-content-center " + collapseOut}
                navbar
                isOpen={collapseOpen}
                onExiting={onCollapseExiting}
                onExited={onCollapseExited}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column flex-lg-row justify-content-between">
                    <Nav className="nav-link-space d-flex flex-column flex-lg-row">
                      <NavItem>
                        <a
                          href={`${Config.HOME_URL}/ppm-1`}
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            !window.location.href.includes("")
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          PPM-1
                        </a>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <a
                          href={`${Config.HOME_URL}/pricing`}
                          target="_blank"
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            window.location.href.indexOf("/pricing") > -1
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          Pricing
                        </a>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <a
                          href={`${Config.HOME_URL}/research`}
                          className={`ff-book font-style px-lg-3 mr-2 mr-xl-5 ${
                            window.location.href.indexOf("/research") > -1
                              ? "navtext-active"
                              : "nav-text"
                          }`}
                        >
                          Research
                        </a>
                      </NavItem>
                      <NavItem className="mt-2 mt-lg-0">
                        <a
                          href={`${Config.HOME_URL}/about`}
                          className="nav-text ff-book font-style px-lg-3 mr-2 mr-xl-5"
                        >
                          About
                        </a>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-close" xs="6">
                        <div className="nav-close cursor-pointer">
                          <AiOutlineClose
                            color="white"
                            size="40"
                            aria-expanded={collapseOpen}
                            onClick={toggleCollapse}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* {Integration_Process === "completed" ? <div className="bg-btn d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer" onClick={() => window.location.href = Config.DASHBOARD_URL}>Dashboard</div> : (Integration_Process === "not_completed" && user_logged === "done") ? <div className="bg-btn d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer" onClick={() => window.location.href = `${Config.DASHBOARD_URL}/integration/steps`}>Complete Setup</div> : <div className="bg-btn d-block d-lg-none py-2 px-4 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer" onClick={() => window.location.href = `${Config.DASHBOARD_URL}/login`}>Login</div>} */}
                {user_logged === "done" && (
                  <div
                    className="d-block d-lg-none bg-btn py-2 px-3 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer"
                    onClick={() => setShowConfirmation(!showConfirmation)}
                  >
                    Logout
                  </div>
                )}
              </Collapse>
            </Col>
            <Col
              lg="2"
              xl="2"
              className="text-right px-0 d-none d-lg-flex justify-content-end"
            >
              {user_logged === "done" && (
                <div
                  className="bg-btn py-2 px-3 mt-4 mt-lg-0 ff-medium text-banner cursor-pointer"
                  onClick={() => setShowConfirmation(!showConfirmation)}
                >
                  Logout
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Navbar>

      <Logout
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
      />
    </>
  );
}
