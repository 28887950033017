import React, { useEffect, useState } from "react";
import {
    Row,
    Col
} from "reactstrap";
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data'
import { Helmet } from "react-helmet";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";


const Verification = () => {

    const history = useHistory()

    const [timezone, setTimezone] = useState('')

    useEffect(() => {
        const verified = secureLocalStorage.getItem('verified')
        const integration = secureLocalStorage.getItem('integration_process')
        const user_logged = secureLocalStorage.getItem('is_loggedin')

        if (verified && user_logged === 'done') {
            if (integration === 'completed') {
                history.push('/dashboard')
            } else {
                history.push('/integration/steps')
            }
        } else {
            history.push('/verification')
        }
    }, [])

    useEffect(() => {
        var userTimezone = moment.tz.guess();
        setTimezone(userTimezone)
    }, [])

    // Resend Email
    const handleResendLink = () => {

        const params = new FormData();
        params.append("email", secureLocalStorage.getItem("storedEmail"))

        Service.post({
            url: `/resend_verification_email/`,
            formdata: true,
            body: params
        })
            .then((response) => {
                if (response && response.status === true) {
                    openNotification(
                        "success",
                        "Success!",
                        "Verification link sent to your email again!"
                    );
                } else {
                    openNotification(
                        "error",
                        "Oops!",
                        response && response.message
                    );
                    history.push('/login')
                    return;
                }
            })
            .catch((err) => {
                console.log(err);
                openNotification(
                    "error",
                    "Oops!",
                    "Something went wrong. Please try later!"
                );
            });
    };

    return (
        <div className="bg-dark login-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Verification</title>
            </Helmet>
            <div className='bg-left-auth d-flex flex-column align-items-center justify-content-center'>
                <Row className="w-100">
                    <Col lg="3"></Col>
                    <Col lg="6" className="text-center verification-screen">
                        <img src={require('../../../assets/img/Dashboard/check-mark.png')} className="img-fluid check-mark" />
                        <p className="text-gray font-style ff-light mt-4 text-center text-22">
                            We sent you a verification email.
                        </p>
                        <p className="text-gray font-style ff-light text-center text-22">
                            Please click on the link in your email to continue.
                        </p>
                        <p className="text-blue cursor-pointer text-20 ff-light" onClick={handleResendLink}>Resend Link</p>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </div>
        </div>
    )
}

export default Verification