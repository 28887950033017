// import { MenuItems } from "components/MenuItems";
// import Navbar from "components/NavbarDashboard";
// import PaymentNotification from "components/PaymentNotification";
import { Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { TiTick } from "react-icons/ti";
import secureLocalStorage from "react-secure-storage";
import BillingOptions from "./BillingOptions";
import { size } from "lodash";
// import Loader from "components/Loader";

function App() {
  const [priceDetails, setpriceDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [billingPop, setBilPop] = useState();
  const GetPricingDetails = () => {
    let plan_id = secureLocalStorage.getItem("plan_id");
    Service.get({
      url: `/billing/plan/${plan_id}/`,
    })
      .then((response) => {
        if (response && response.status) {
          setpriceDetails(response && response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setpriceDetails({});
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  useEffect(() => {
    GetPricingDetails();
  }, []);

  const updateText = (text) => {
    let updatedText = text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return updatedText;
  };

  async function HandleClick(id) {
    Service.get({
      url: `/billing/plans/subscribe/?plan_id=${id}`,
    })
      .then((response) => {
        if (response && response.status && response.confirmation_url) {
          window.location.href = response?.confirmation_url;
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  }
  return (
    <div className="">
      <div>
        {/* <h1 className="text-dark text-center heading_text_billing ff-inter-regular">
              Select a plan to get started with PRIYA
            </h1>
            <p className="mb-4 ff-inter-semi text-center text_billing">3 DAY FREE TRIAL INCLUDED</p> */}
      </div>
      <div className="main_box_subscribed">
        <div className="grid_box payment-card  billing-settings ">
          {/* {priceDetails.map((el) => {
                return ( */}
          {priceDetails?.title && (
            <div
              className={`custom-shadow ${
                priceDetails?.most_popular ? "most_popular_selected" : ""
              } billing_card`}
            >
              {priceDetails?.most_popular && (
                <div className="most_popular ff-inter-bold">Most Popular</div>
              )}
              <div className="pkg_name  ff-inter-semi">
                {priceDetails?.title}
              </div>
              <div className="main_price_box">
                <p className="text-gray dollar_box">$</p>
                <div className="ff-inter-bold text-dark price_box">
                  {priceDetails?.price}
                </div>
                <div className="mo_section">/mo</div>
              </div>
              {!priceDetails?.subscribed && (
                <div>
                  <div className="margin_box">
                    {priceDetails?.products_limit && (
                      <div className="ff-inter-medium d-flex text-14">
                        <TiTick className="text-dark" size={20} />
                        <div className="text-gray">
                          Up to {priceDetails?.products_limit} Products
                        </div>
                      </div>
                    )}
                    {priceDetails?.price_updating_frequency && (
                      <div className="ff-inter-medium d-flex text-14">
                        <TiTick className="text-dark" size={20} />
                        <div className="text-gray">
                          {priceDetails?.autonomus_pricing &&
                            "Autonomous pricing"}
                        </div>
                      </div>
                    )}
                    {/* {priceDetails?.price_updating_frequency && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            {updateText(priceDetails?.price_updating_frequency)} Price
                            Testing
                          </div>
                        </div>
                      )} */}
                    {priceDetails?.pricing_data_reporting && (
                      <div className="ff-inter-medium d-flex text-14">
                        <TiTick className="text-dark" size={20} />
                        <div className="text-gray">
                          Price Data And Reporting
                        </div>
                      </div>
                    )}
                    {priceDetails?.api_access && (
                      <div className="ff-inter-medium d-flex text-14">
                        <TiTick className="text-dark" size={20} />
                        <div className="text-gray">Access to PRIYA API</div>
                      </div>
                    )}
                    {/* Since it is included in each plan, it is hardcoded. */}
                    <div className="ff-inter-medium d-flex text-14">
                        <TiTick className="text-dark" size={20} />
                        <div className="text-gray">AI-powered Analytics</div>
                      </div>
                  </div>
                  <button
                    onClick={() => {
                      setBilPop(true);
                      // HandleClick(priceDetails?.id);
                    }}
                    className="subscribe_btn ff-inter-semi btn-gray"
                  >
                    Update Plan
                  </button>
                </div>
              )}
            </div>
          )}
          <BillingOptions setBilPop={setBilPop} billingPop={billingPop} />
        </div>
      </div>
    </div>
  );
}

export default App;
