import { MenuItems } from "components/MenuItems";
import Navbar from "components/NavbarDashboard";
import React, { useState } from "react";
import { Label, Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import Loader from "components/Loader";
import { Eye, EyeOff } from "react-feather";

function Settings() {
  const email = secureLocalStorage.getItem("email");
  const token = secureLocalStorage.getItem("token");

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const [password, setPassword] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loginPasswordVisibility, setLoginPasswordVisibility] = useState(false);

  const onSubmitEmail = (data) => {};

  const onSubmitPassword = (data) => {
    const params = new FormData();
    params.append("token", token);
    params.append("password", password);

    setButtonDisable(true);

    Service.post({
      url: "/update-password/",
      body: params,
      formdata: true,
    })
      .then((response) => {
        setButtonDisable(false);
        if (response && response.status === true) {
          openNotification("success", "Success!", response && response.message);
        } else {
          openNotification("error", "Oops!", response && response.message);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonDisable(false);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  return (
    <div className="bg-white dashboard-page d-flex flex-column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Settings</title>
      </Helmet>
      <Navbar />
      {/* <PaymentNotification /> */}
      <div className="d-flex flex-column flex-xl-row project-section flex-grow">
        <div className="d-flex align-items-center align-items-xl-start pr-0 menu-view">
          <div className="d-none d-xl-block">
            <MenuItems />
          </div>
          <span className="d-block d-sm-none ml-3 ff-inter-semi my-2 text-xl ">
            {window.location.href.indexOf("/billing") > -1
              ? "Billing"
              : window.location.href.indexOf("/settings") > -1
              ? "Settings"
              : window.location.href.indexOf("/logout") > -1
              ? "Logout"
              : "Dashboard"}
          </span>
        </div>
        <div className="w-100 content-view p-3 p-xl-4 h-full">
          <div className="bg-white billing-settings p-3 h-full">
            <div className="settings-screen ">
              <h3 className="ff-inter-semi text-dark">Settings</h3>
              <Form onSubmit={handleSubmit(onSubmitEmail)} className="mt-3">
                <FormGroup className="w-40p">
                  <Label className="text-darkgray ff-inter-semi text-17 mb-3">
                    User Email
                  </Label>
                  <Controller
                    id="userEmail"
                    name="userEmail"
                    defaultValue={email}
                    type="email"
                    disabled
                    // rules={{
                    //     required: 'Please enter your email'
                    // }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        invalid={formState.errors.userEmail && true}
                        placeholder="davidgrey@priya.com"
                        {...field}
                        className="py-4 w-70"
                      />
                    )}
                  />
                  {formState.errors.userEmail && (
                    <FormFeedback>
                      {formState.errors.userEmail.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <button
                  type="submit"
                  className="text-14 ff-inter-semi mt-4 btn-gray py-250"
                >
                  Update Email Address
                </button>
              </Form>
              <Form className="mt-5">
                <FormGroup className="position-rel w-40p">
                  <Label className="text-darkgray ff-inter-semi text-17 mb-3">
                    User Password
                  </Label>
                  <Controller
                    id="userPassword"
                    name="userPassword"
                    rules={{
                      required: "Please enter your password",
                    }}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type={loginPasswordVisibility ? "text" : "password"}
                          invalid={formState.errors.userPassword && true}
                          {...field}
                          className="py-4"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="settings-password-toggle"
                          onClick={() => {
                            setLoginPasswordVisibility(
                              !loginPasswordVisibility
                            );
                          }}
                        >
                          {loginPasswordVisibility ? (
                            <Eye size="14" color="gray" />
                          ) : (
                            <EyeOff size="14" color="gray" />
                          )}
                        </div>
                      </>
                    )}
                  />
                  {formState.errors.userPassword && (
                    <FormFeedback>
                      {formState.errors.userPassword.message}
                    </FormFeedback>
                  )}
                </FormGroup>
                <button
                  type="button"
                  onClick={onSubmitPassword}
                  className={`text-14 ff-inter-semi mt-4 btn-gray ${
                    buttonDisable ? "py-2" : "py-250"
                  }`}
                >
                  {buttonDisable ? <Loader /> : "Update Password"}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
