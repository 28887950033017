import React from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
// Custom
import Index from "views/priya_ai/Authentication/LoginScreen";
import Login from "views/priya_ai/Authentication/Login";
import Signup from "views/priya_ai/Authentication/Signup";
import { AnchorScroll } from 'helper';
import Dashboard from 'views/priya_ai/Dashboad/Dashboard';
import Billing from 'views/priya_ai/Dashboad/Billing';
import PaymentMethod from 'views/priya_ai/Dashboad/PaymentMethod';
import BillingOptions from "views/priya_ai/Dashboad/BillingOptions"
import BillingOverview from 'views/priya_ai/Dashboad/BiilingOverview';
import BillingSettings from 'views/priya_ai/Dashboad/BillingSetting';
import Settings from 'views/priya_ai/Dashboad/Settings';
import BillingHistory from 'views/priya_ai/Dashboad/BillingHistory';
import PrivateRoute from './PrivateRoute';
import PrivateIntegrationRoute from './PrivateIntegrationRoute';
import IntegrationModal from 'components/IntegrationModal';
import Verification from 'views/priya_ai/Authentication/VerificationScreen';
import secureLocalStorage from "react-secure-storage";
import VerifyEmail from 'views/priya_ai/Authentication/VerifyingScreen';
import ForgotPassword from 'views/priya_ai/Authentication/ForgotPassword';
import ResetPassword from 'views/priya_ai/Authentication/ResetPassword';
import Success from 'components/Success';
import Error from 'components/Error';
import ShopifyAuth from 'components/ShopifyAuth';
import CallbackAuth from 'components/CallbackAuth';


export default function Routes() {
    React.useEffect(() => {
        AOS.init();
        AnchorScroll()
    });

    const verifiedEmail = secureLocalStorage.getItem('verified')

    const isAuthenticated = () => {
        return secureLocalStorage.getItem('is_loggedin') !== null;
    };

    return (
        <>
            <BrowserRouter>
                <Switch>
                    {/* <PrivateIntegrationRoute
                        path="/integration/steps"
                        component={IntegrationModal}
                    /> */}
                    {/* <PrivateRoute
                        path="/billing/payment-methods"
                        component={PaymentMethod}
                    /> */}
                    <PrivateRoute
                        path="/billing/overview"
                        component={BillingOverview}
                    />
                    <PrivateRoute
                        path="/billing/history"
                        component={BillingHistory}
                    />

                    <PrivateRoute
                        path="/billing/setting"
                        component={BillingSettings}
                    />
                    <PrivateRoute
                        path="/settings"
                        component={Settings}
                    />
                    {verifiedEmail ? <PrivateRoute
                        path="/integration/steps"
                        component={IntegrationModal}
                    /> :
                        <Route exact path="/verification" render={(props) => <Verification {...props} />} />}

                    <Route exact path="/auth" render={(props) => <Index {...props} />} />
                    <Route exact path="/signup" render={(props) => <Signup {...props} />} />
                    <Route exact path="/login" render={(props) => <Login {...props} />} />
                    <Route exact path="/password-reset" render={(props) => <ResetPassword {...props} />} />
                    <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                    <Route exact path="/verification" render={(props) => <Verification {...props} />} />
                    <Route exact path="/verify_email" render={(props) => <VerifyEmail {...props} />} />
                    <Route exact path="/shopify/success" render={(props) => <Success {...props} />} />
                    <Route exact path="/shopify/error" render={(props) => <Error {...props} />} />
                    <Route exact path="/shopify/auth" render={(props) => <ShopifyAuth {...props} />} />
                    <Route exact path="/shopify/callback" render={(props) => <CallbackAuth {...props} />} />
                    <Route exact path="/billing" render={(props) => <Billing {...props} />} />
                    <PrivateRoute
                        path="/"
                        component={Dashboard}
                    />
                </Switch>
            </BrowserRouter>
        </>
    )
}