import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import IntegrationModal from 'components/IntegrationModal';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        return secureLocalStorage.getItem('is_loggedin') !== null;
    };
    const isBilled = () => {
        return secureLocalStorage.getItem('is_billed') !== null;
    };

    const isIntegrated = () => {
        return secureLocalStorage.getItem('integration_process') === 'completed';
    };
    return (
        <Route
            exact
            {...rest}
            render={(props) =>
                isAuthenticated() ? <>
                    {
                        isBilled() ? <Component {...props} /> : <><Redirect to={{ pathname: '/billing' }} /></>
                    }
                </> : (
                    <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
                )
            }
        />
    );
};
{/* isIntegrated() ? <Component {...props} /> : <><Redirect to={{ pathname: '/integration/steps' }} /> <IntegrationModal {...props} /></> */}
export default PrivateRoute;