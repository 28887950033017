// import { MenuItems } from "components/MenuItems";
// import Navbar from "components/NavbarDashboard";
// import PaymentNotification from "components/PaymentNotification";
import { Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import BillingPlans from "components/BillingPlans";
import { TiTick } from "react-icons/ti";
// import Loader from "components/Loader";

function BillingOptions({ billingPop }) {
  const [priceDetails, setpriceDetails] = useState([]);
  const [showBilling, setShowBilling] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setShowBilling(billingPop);
  }, [billingPop]);

  useEffect(() => {
    if (showBilling) {
      document.body.classList.add("bill-modal-open");
    } else {
      document.body.classList.remove("bill-modal-open");
    }
    return () => {
      document.body.classList.remove("bill-modal-open");
    };
  }, [showBilling]);

  const GetPricingDetails = () => {
    Service.get({
      url: `/billing/plans/`,
    })
      .then((response) => {
        if (response && response.status) {
          console.log(response.data);
          setpriceDetails(response && response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setpriceDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  useEffect(() => {
    GetPricingDetails();
  }, []);

  const updateText = (text) => {
    let updatedText = text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return updatedText;
  };

  async function HandleClick(id) {
    Service.get({
      url: `/billing/plans/subscribe/?plan_id=${id}`,
    })
      .then((response) => {
        if (response && response.status && response.confirmation_url) {
          window.location.href = response?.confirmation_url;
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  }
  return (
    <Modal isOpen={showBilling} backdrop={true} className="payment-plans-modal">
      <ModalBody
        className="modal-body-plans text-center p-0"
        // style={{ backgroundColor: "transparent" }}
      >
        {showBilling && (
          <>
            <div>
              <h1 className="text-dark text-center heading_text_billing ff-inter-regular">
                Select a plan to get started with PRIYA
              </h1>
              <p className="mb-4 ff-inter-semi text-center p_text_billing">
                START WITH A 7 DAY FREE TRIAL
              </p>
              {/* <p className="mb-4 ff-inter-semi text-center p_text_billing">ALL PLANS COME WITH A 30 DAY GUARANTEE</p> */}
            </div>
          </>
        )}
        <BillingPlans />
        {/* <div className="">
          <div>
            <h1 className="text-dark heading_text_billing ff-inter-regular">
              Select a plan to get started with PRIYA
            </h1>
            <p className="mb-4 ff-inter-semi p_text_billing">3 DAY FREE TRIAL INCLUDED</p>
          </div>
          <div className="main_box">
            <div className="grid_box payment-card  billing-settings ">
              {priceDetails.map((el) => {
                return (
                  <div className={`custom-shadow ${el?.most_popular?"most_popular_selected":""} billing_card`}>
                    {el.most_popular && (
                      <div className="most_popular ff-inter-bold">Most Popular</div>
                    )}
                    <div className="pkg_name  ff-inter-semi">{el.title}</div>
                    <div className="main_price_box">
                      <p className="text-gray dollar_box">$</p>
                      <div className="ff-inter-bold text-dark price_box">
                        {el.price}
                      </div>
                      <div className="mo_section">/mo</div>
                    </div>
                    <div className="margin_box">
                      {el.trial_days && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            {el.trial_days} Days Free Trial
                          </div>
                        </div>
                      )}
                      {el.products_limit && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            Up to {el.products_limit} Products
                          </div>
                        </div>
                      )}
                      {el.price_updating_frequency && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            {el.autonomus_pricing && "Autonomous pricing"}
                          </div>
                        </div>
                      )}
                      {el.pricing_data_reporting && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            Price Data And Reporting
                          </div>
                        </div>
                      )}
                      {el.api_access && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">Access to PRIYA API</div>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        HandleClick(el?.id);
                      }}
                      className="subscribe_btn ff-inter-semi btn-gray"
                    >
                      Select Plan
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
      </ModalBody>
    </Modal>
  );
}

export default BillingOptions;
