// import { MenuItems } from "components/MenuItems";
// import Navbar from "components/NavbarDashboard";
// import PaymentNotification from "components/PaymentNotification";
import { Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import BillingPlans from "components/BillingPlans/BillingOptions/BillingPlans"
import { TiTick } from "react-icons/ti";
// import Loader from "components/Loader";

function BillingOptions({ billingPop,setBilPop }) {
  const [priceDetails, setpriceDetails] = useState([]);
  const [showBilling, setShowBilling] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setShowBilling(billingPop);
  }, [billingPop]);

  useEffect(() => {
    if (showBilling) {
      document.body.classList.add('bill-modal-open');
    } else {
      document.body.classList.remove('bill-modal-open');
    }
    return () => {
      document.body.classList.remove('bill-modal-open');
    };
  }, [showBilling]);

  const GetPricingDetails = () => {
    Service.get({
      url: `/billing/plans/`,
    })
      .then((response) => {
        if (response && response.status) {
          setpriceDetails(response && response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setpriceDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  useEffect(() => {
    GetPricingDetails();
  }, []);

  const updateText = (text) => {
    let updatedText = text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return updatedText;
  };

  async function HandleClick(id) {
    Service.get({
      url: `/billing/plans/subscribe/?plan_id=${id}`,
    })
      .then((response) => {
        if (response && response.status && response.confirmation_url) {
          window.location.href = response?.confirmation_url;
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  }
  return (
    <Modal isOpen={showBilling} toggle={() => setBilPop(!billingPop)} backdrop={true} className="payment-plans-modal">
      <ModalBody
        className="modal-body-plans text-center p-0"
        // style={{ backgroundColor: "transparent" }}
      >
      <BillingPlans/>
      </ModalBody>
    </Modal>
  );
}

export default BillingOptions;
