import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import secureLocalStorage from "react-secure-storage";
import Config from "../../configs/config.json";

const Logout = ({ showConfirmation, setShowConfirmation }) => {
  return (
    <Modal
      isOpen={showConfirmation}
      backdrop={true}
      toggle={() => setShowConfirmation(!showConfirmation)}
      className="modal-dialog-centered payment-modal"
    >
      <ModalHeader className="bg-header">
        <div>
          <img
            src={require("../../assets/img/Priya_AI/logo-black.png")}
            className="img-fluid logo-mobile"
            width={100}
          />
          <span className="ml-3 ff-inter-semi text-14">LOGOUT</span>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setShowConfirmation(!showConfirmation)}
        >
          <AiOutlineClose color="#000" size="15" />
        </div>
      </ModalHeader>
      <ModalBody className="my-3">
        <p className="ff-inter-medium text-dark mb-5 text-16">
          Are you sure you'd like to logout?
        </p>
        <div className="d-flex justify-content-between align-items-center">
          <button
            className={`ff-inter-semi py-2 text-gray button-gradient mr-2`}
            onClick={() => {
              secureLocalStorage.clear();
              setShowConfirmation(!showConfirmation);
              window.location.href = Config.HOME_URL;
            }}
          >
            Yes
          </button>
          <button
            className={`ff-inter-semi py-2 text-gray button-white ml-2`}
            onClick={() => setShowConfirmation(!showConfirmation)}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Logout;
