import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  FormFeedback,
  Modal,
  ModalBody,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory, Link } from "react-router-dom";
import Loader from "components/Loader";
import BillingOptions from "../Dashboad/BillingOptions";
// import { LoginSocialGoogle } from "reactjs-social-login";
import secureLocalStorage from "react-secure-storage";
import { Eye, EyeOff } from "react-feather";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import Config from "../../../configs/config.json";
import { Helmet } from "react-helmet";
import { AuthorizeShopify } from "components/AuthorizeShopify";
import { GrHost } from "react-icons/gr";

const Login = () => {
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loginPasswordVisibility, setLoginPasswordVisibility] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [storeReconnect, setStoreReconnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingPop, setBilPop] = useState("");
  const { control, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const Integration_Process = secureLocalStorage.getItem(
      "integration_process"
    );
    const user_logged = secureLocalStorage.getItem("is_loggedin");

    if (Integration_Process === "completed" && user_logged === "done") {
      history.push("/");
    } else if (
      Integration_Process === "not_completed" &&
      user_logged === "done"
    ) {
      history.push("/integration/steps");
    }
  }, []);

  useEffect(() => {
    var userTimezone = moment.tz.guess();
    setTimezone(userTimezone);
  }, []);

  // const getSessionToken = (code) => {
  //   const jwt = require("jsonwebtoken");

  //   const api_key = "054ab8df648cce8cab118f1c4d8e142c";
  //   const secret = "82b825631c73d0bb2324d3030d2640c6";
  //   const payload = {
  //     exp: Math.floor(Date.now() / 1000) + 60 * 30,
  //     nbf: Math.floor(Date.now() / 1000),
  //     iss: "127.0.0.1:8000",
  //     dest: "storeForDetail.myshopify.com",
  //     aud: api_key,
  //     jti: code,
  //   };
  //   const secretBuffer = Buffer.from(secret, "utf8");
  //   try {
  //     const session_token = jwt.sign(payload, secretBuffer, {
  //       algorithm: "HS256",
  //     });
  //     console.log(session_token);
  //     if (session_token) {
  //       console.log("token");
  //       secureLocalStorage.setItem("token", session_token);
  //     }
  //   } catch (error) {
  //     console.error("Error generating token:", error);
  //   }

  //   // FOR GENERATING SESSION_TOKEN FROM BACKEND
  //   // try{
  //   //     const header = {
  //   //         "content-type": "application/json",
  //   //         accept: "application/json",
  //   //         token:code
  //   //     }

  //   // fetch(Config.BASE_URL+`/shopify/login/`,{
  //   //     method: "GET",
  //   //     headers: header,
  //   // })
  //   //     .then(response => {
  //   //         if (response && response.status === true) {
  //   //             const session_token=response?.user_data?.access_token
  //   //             console.log(session_token)
  //   //             if (session_token) {
  //   //                 console.log('token')
  //   //                 secureLocalStorage.setItem(
  //   //                     "token",
  //   //                     session_token
  //   //                 );
  //   //             }
  //   //         }
  //   //     }
  //   //     )
  //   //     .catch(err => {
  //   //         console.log("The err in Get User Data")
  //   //         console.log(err)
  //   //     })
  //   // }catch(err){
  //   //     console.log("")
  //   // }
  // };

  //Login
  const onLoginSubmit = (data) => {
    const params = new FormData();
    params.append("email", data.loginEmail);
    params.append("password", data.loginPassword);
    params.append("timezone", timezone);

    setButtonDisable(true);

    Service.post({
      url: "/login/",
      body: params,
      formdata: true,
    })
      .then((response) => {
        setButtonDisable(false);
        secureLocalStorage.setItem(
          "token",
          response && response.user_data && response.user_data.access_token
        );
        secureLocalStorage.setItem(
          "refreshToken",
          response && response.user_data && response.user_data.refresh_token
        );
        secureLocalStorage.setItem("initialDashboard", true);
        if (response && response.status === true) {
          // getSessionToken(
          //   response && response.user_data && response.user_data.code
          // );
          // secureLocalStorage.setItem(
          //     "token",
          //     response && response.user_data && response.user_data.access_token
          // );
          // secureLocalStorage.setItem(
          //     "refreshToken",
          //     response && response.user_data && response.user_data.refresh_token
          // );
          secureLocalStorage.setItem(
            "email",
            response && response.user_data && response.user_data.email
          );
          secureLocalStorage.setItem("verified", true);
          secureLocalStorage.setItem("is_loggedin", "done");
          secureLocalStorage.setItem(
            "integration_process",
            response &&
              response.user_data &&
              response.user_data.configuration_status
              ? "completed"
              : "not_completed"
          );
          const currentTime = new Date();
          const twoHoursFromNow = new Date(
            currentTime.getTime() + 2 * 60 * 60 * 1000
          );
          secureLocalStorage.setItem("Expired", twoHoursFromNow.toString());

          if (
            response &&
            response.user_data &&
            response.user_data.configuration_status
          ) {
            if (!response.user_data.plan_configured) {
              setBilPop(true);
            } else {
              secureLocalStorage.setItem("is_billed", true);
              history.push("/");
            }
          } else {
            history.push("/integration/steps");
          }
        }
        //  else if (
        //   response &&
        //   response.status === false &&
        //   response.message.includes("verification")
        // ) {
        //   secureLocalStorage.setItem("storedEmail", data.loginEmail);
        //   openNotification(
        //     "error",
        //     "Oops!",
        //     "Please verify your email to continue"
        //   );
        //   history.push("/");
        // }
        else if (
          response &&
          response.status === false &&
          response.message.includes("lost connection")
        ) {
          setStoreReconnect(!storeReconnect);
          // getSessionToken(
          //   response && response.user_data && response.user_data.code
          // );
          // secureLocalStorage.setItem(
          //     "token",
          //     response && response.user_data && response.user_data.access_token
          // );
          // secureLocalStorage.setItem(
          //     "refreshToken",
          //     response && response.user_data && response.user_data.refresh_token
          // );
          secureLocalStorage.setItem(
            "email",
            response && response.user_data && response.user_data.email
          );
          secureLocalStorage.setItem("verified", true);
          secureLocalStorage.setItem("is_loggedin", "done");
          secureLocalStorage.setItem("integration_process", "not_completed");
          const currentTime = new Date();
          const twoHoursFromNow = new Date(
            currentTime.getTime() + 2 * 60 * 60 * 1000
          );
          secureLocalStorage.setItem("Expired", twoHoursFromNow.toString());
        } else {
          openNotification("error", "Oops!", response && response.message);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonDisable(false);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  const ReconnectStore = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/integration/steps");
    }, 2000);
  };

  return (
    <div className="bg-dark login-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Login</title>
      </Helmet>
      <div className="bg-left-auth d-flex flex-column align-items-center justify-content-between">
        <div className="py-5">
          <a href={`${Config.HOME_URL}`}>
            <img
              src={require("../../../assets/img/Priya_AI/logo.png")}
              className="img-fluid logo-beta"
              width="155px"
            />
          </a>
        </div>
        <Row className="w-100">
          <Col lg="4"></Col>
          <Col lg="4">
            <h2 className="text-white text-center ff-light mb-4">
              Welcome Back
            </h2>
            <Form
              onSubmit={handleSubmit(onLoginSubmit)}
              className="login-modal d-flex flex-column align-items-center justify-content-center"
            >
              <FormGroup className="">
                <Controller
                  id="loginEmail"
                  name="loginEmail"
                  type="email"
                  rules={{
                    required: "Please enter your email",
                  }}
                  control={control}
                  render={({ field }) => (
                    <Input
                      invalid={formState.errors.loginEmail && true}
                      placeholder="Email"
                      {...field}
                      className="py-4"
                    />
                  )}
                />
                {formState.errors.loginEmail && (
                  <FormFeedback>
                    {formState.errors.loginEmail.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="password-field">
                <Controller
                  id="loginPassword"
                  name="loginPassword"
                  rules={{
                    required: "Please enter your password",
                  }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Password"
                        type={loginPasswordVisibility ? "text" : "password"}
                        {...field}
                        className="py-4"
                        invalid={formState.errors.loginPassword && true}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => {
                          setLoginPasswordVisibility(!loginPasswordVisibility);
                        }}
                      >
                        {loginPasswordVisibility ? (
                          <Eye size="14" color="gray" />
                        ) : (
                          <EyeOff size="14" color="gray" />
                        )}
                      </div>
                    </>
                  )}
                />
                {formState.errors.loginPassword && (
                  <FormFeedback>
                    {formState.errors.loginPassword.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`sign-button btn-white d-flex align-items-center justify-content-center text-dark mt-3 ff-bold ${
                    buttonDisable ? "py-2" : "py-3"
                  }`}
                >
                  <span className="ff-bold">
                    {buttonDisable ? <Loader /> : "Sign In"}
                  </span>
                </button>
              </div>
            </Form>
            <p className="text-gray ff-light mt-4 text-center">
              Forgot your password?
              <span
                className="text-banner cursor-pointer ml-2"
                onClick={() => history.push("/forgot-password")}
              >
                Reset it
              </span>
            </p>
            {/* <p className="text-gray ff-light mt-2 text-center">
              Don't have an account?{" "}
              <span
                className="text-banner cursor-pointer ml-2"
                onClick={() => history.push("/signup")}
              >
                Sign up
              </span>
            </p> */}
            {/* <div className="d-flex align-items-center justify-content-center pt-3">
              <img
                src={
                  require("../../../assets/img/Dashboard/or-line.png")
                }
                className="img-fluid"
              />
              <span className="text-gray ff-inter-regular px-2">or</span>
              <img
                src={
                  require("../../../assets/img/Dashboard/or-line.png")
                }
                className="img-fluid"
              />
            </div> */}
            <AuthorizeShopify />
            {/*  <div className="d-flex align-items-center justify-content-center">
              <LoginSocialGoogle
                client_id="203064133092-v1m4200a7l46n7gui606r5n4sj2l1nnv.apps.googleusercontent.com"
                scope="https://www.googleapis.com/auth/userinfo.email"
                onResolve={({ provider, data }) => {
                  const endpoint = `${Config.BASE_URL}/login-with-gmail/`;
                  const userTimezone = moment.tz.guess();
                  const formData = new FormData();
                  formData.append("access_token", data.access_token);
                  formData.append("email", data.email);
                  formData.append("timezone", userTimezone);

                  var requestOptions = {
                    method: "POST",
                    body: formData,
                    redirect: "follow",
                  };

                  fetch(endpoint, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                      if (result && result.status === true) {
                        secureLocalStorage.setItem(
                          "token",
                          result &&
                            result.user_data &&
                            result.user_data.access_token
                        );
                        secureLocalStorage.setItem(
                          "refreshToken",
                          result &&
                            result.user_data &&
                            result.user_data.refresh_token
                        );
                        secureLocalStorage.setItem(
                          "email",
                          result && result.user_data && result.user_data.email
                        );
                        secureLocalStorage.setItem("is_loggedin", "done");
                        secureLocalStorage.setItem(
                          "integration_process",
                          result &&
                            result.user_data &&
                            result.user_data.configuration_status
                            ? "completed"
                            : "not_completed"
                        );
                        const currentTime = new Date();
                        const twoHoursFromNow = new Date(
                          currentTime.getTime() + 2 * 60 * 60 * 1000
                        );
                        secureLocalStorage.setItem(
                          "Expired",
                          twoHoursFromNow.toString()
                        );
                        if (
                          result &&
                          result.user_data &&
                          result.user_data.configuration_status
                        ) {
                          history.push("/");
                        } else {
                          history.push("/integration/steps");
                        }
                      } else {
                        openNotification(
                          "error",
                          "Oops!",
                          result && result.error
                        );
                        return;
                      }
                    })
                    .catch((error) => console.log("error", error));
                }}
                onReject={({ error }) => {
                  console.log(error);
                }}
              >
                <button
                  type="button"
                  className="btn-transparent d-flex align-items-center justify-content-center py-2 px-4 text-white mt-3 ff-inter-semi"
                >
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/glogin.png")
                    }
                    className="img-fluid ff-inter-semi py-0"
                  />
                  <span className="text-14 ff-inter-semi ml-2">
                    Sign in with Google
                  </span>
                </button>
              </LoginSocialGoogle>
            </div> */}
          </Col>
          <Col lg="4"></Col>
        </Row>
        <div className="text-center my-5">
          <div className="ff-inter-regular mt-4 text-gray">
            <a
              href={`${Config.HOME_URL}/privacypolicy`}
              target={"_blank"}
              className="text-gray"
            >
              Privacy Policy
            </a>{" "}
            <span className="px-2">|</span>{" "}
            <a
              href={`${Config.HOME_URL}/termsofuse`}
              target={"_blank"}
              className="text-gray"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>

      {/* Resume Store Connection */}
      <BillingOptions billingPop={billingPop} />
      <Modal
        isOpen={storeReconnect}
        backdrop={true}
        className="modal-dialog-centered payment-popup"
      >
        <ModalBody
          className="p-2 p-sm-5 text-center"
          style={{ backgroundColor: "#f4f4f4" }}
        >
          <a href={`${Config.HOME_URL}/`} target="_blank">
            <img
              src={require("../../../assets/img/Priya_AI/logo-black.png")}
              className="img-fluid mt-3 mt-sm-0 mb-4"
              width={155}
            />
          </a>
          <p className="expiry-text ff-inter-semi text-dark mb-0 text-18">
            PRIYA has lost connection to your store. Please connect again to
            resume use.
          </p>
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-5">
            <button
              className={`text-16 btn-reconnect green-button ff-inter-semi mr-sm-2 py-1`}
              type="button"
              onClick={() => ReconnectStore()}
            >
              {loading ? "Loading.." : "Re-connect"}
            </button>
            <button
              className="text-16 border-20 ff-inter-semi text-gray btn-cancel btn-reconnect py-1 my-3 my-sm-0"
              onClick={() => {
                setStoreReconnect(!storeReconnect);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Login;
