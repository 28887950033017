
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientSVG";

export default function PieChart({ data }) {

    const idCSS = "hello";
    const percentage = data === null ? '0' : data && Math.round(data);

    return (
        <div className="App">
            <div style={{ height: "50px", width: "50px" }}>
                <GradientSVG />
                <CircularProgressbar
                    strokeWidth={14}
                    value={percentage}
                    text={`${percentage}%`}
                    // styles={{
                    //     path: { stroke: `url(#${idCSS})`, height: "100%" },
                    //     trail: {
                    //         stroke: "#efefef"
                    //     },
                    //     text: {
                    //         // Text color
                    //         fill: "gray",
                    //         // Text size
                    //         fontSize: "24px",
                    //         fontWeight: "bold",
                    //     },
                    //     strokeLinecap: "butt"
                    // }}
                    styles={buildStyles({
                        pathColor: `url(#hello)`,
                        textColor: "gray",
                        textSize: "24px",
                        rotation: 0.5 + (1 - percentage / 100) / 2
                    })}
                />

            </div>
        </div>
    );
}


