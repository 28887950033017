import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { AiOutlineClose } from "react-icons/ai";
import FlatPicker from "react-flatpickr";
import { Service } from 'services/Service';
import { openNotification } from 'components/Helper';
import Loader from 'components/Loader';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';

const PriceHistory = ({ isOpen, toggle, data, startDate, setStartDate }) => {

    const [priceHistory, setPriceHistory] = useState([])
    const [loading, setLoading] = useState(false)

    const getPriceHistory = () => {
        const inputDate = new Date(startDate);

        // Convert to UTC
        const utcDateString = inputDate.toISOString();

        const params = {
            date: moment(utcDateString).format('YYYY-MM-DD'),
            product_id: Number(data && data.product_id)
        }

        setLoading(true)

        Service.get({
            url: `/price-history/?date=${params.date}&product_id=${params.product_id}`,
            formdata: true
        })
            .then(response => {
                if (response && response.status === true) {
                    setPriceHistory(response.data)
                    setLoading(false)
                } else {
                    setPriceHistory([])
                }
            }
            )
            .catch(err => {
                console.log(err)
                openNotification('error', 'Oops!', 'Something went wrong. Please try later!')
            })
    }

    useEffect(() => {
        if (isOpen) {
            getPriceHistory()
        }
    }, [isOpen, startDate])

    const showTime = (date) => {
        var dateString = date;
        var formatString = 'YYYY-MM-DD hh:mm:ss A';

        var parsedDate = moment(dateString, formatString);
        var timeOnly = parsedDate.format('hh:mm a');

        return timeOnly
    }

    return (
        <Modal isOpen={isOpen} backdrop={true} toggle={toggle} size="md" className="price-history-modal modal-dialog-centered payment-modal">
            <ModalHeader className="bg-header">
                <div>
                    <img src={require('../../../assets/img/Priya_AI/logo-black.png')} className="img-fluid logo-mobile" width={100} />
                    <span className='ml-3 ff-inter-semi text-14'>VIEW PRICING HISTORY</span>
                </div>
                <div className="cursor-pointer" onClick={toggle}>
                    <AiOutlineClose color="#000" size="15" />
                </div>
            </ModalHeader>
            <ModalBody className='my-3 mx-1 m-sm-3 p-0'>

                <div className='bg-gray-light text-muted p-2 d-flex'>
                    <span className='ff-inter-semi mr-2' style={{ color: "#aeaeae" }}>FILTER BY:</span>
                    <div className='position-rel d-flex'>
                        <div className='position-calendar'>
                            <FaRegCalendarAlt />
                        </div>
                        <FlatPicker
                            options={{ dateFormat: "m/d/Y", defaultDate: new Date() }
                            }
                            selected={startDate}
                            onChange={(date) => { setStartDate(date) }}
                            popperPlacement="top-end"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            className="text-center"
                        />
                    </div>
                </div>
                <div className='mt-4 price-history-box bg-white box-shadow px-sm-2 py-2'>
                    {loading ? <div className='p-5 text-center'><Loader /></div> :
                        priceHistory && priceHistory.length > 0 ? <div className='price-history'>{priceHistory.map((item) => (
                            <div className='bg-price-box py-2 px-0 px-sm-2 my-4'>
                                <p className='text-gray ff-inter-medium mb-0 mr-2 px-1'>{showTime(item.datetime)}</p>
                                <div className='pr-1 d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <img src={require('../../../assets/img/Priya_AI/logo-success.png')} className="img-fluid pr-1" width={45} />
                                            <p className='text-gray ff-inter-medium mb-0 price-font'>Price: <span className='text-dark font-weight-semibold'>${item && item.current_price.toFixed(2)}</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <span className='text-gray ff-inter-medium mb-0 px-1 price-font'>Sales Change: </span>
                                            <div className='d-flex align-items-center' >
                                                <span className='mb-1'>{item && item.sales_change < 0 ? <AiOutlineArrowDown color='red' size={20} /> : <AiOutlineArrowUp color='green' size={20} />}</span><span className='text-dark font-weight-semibold'>{item && item.sales_change}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div> : <div className='text-center my-5 text-dark'>No history found</div>}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default PriceHistory