import React, { useEffect, useState } from "react";
import {
    Row,
    Col
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data'
import { Helmet } from "react-helmet";


const VerifyEmail = () => {

    const urlParams = new URLSearchParams(window.location.search);

    const uid = urlParams.get('uid');
    const token = urlParams.get('token');

    const history = useHistory()
    const [verifyEmail, setVerifyEmail] = useState(true)
    const [error, setError] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            VerifyEmail()
        }, 3000);
    }, [])

    //Verify Email
    const VerifyEmail = () => {

        Service.get({
            url: `/signup/verify_email/?uid=${uid}&token=${token}`,
            formdata: true,
        })
            .then((response) => {
                if (response && response.status === true) {
                    secureLocalStorage.setItem(
                        "token",
                        response && response.access_token
                    );
                    secureLocalStorage.setItem(
                        "refreshToken",
                        response && response.refresh_token
                    );
                    secureLocalStorage.setItem(
                        "email",
                        response && response.customer_email
                    );
                    secureLocalStorage.removeItem("storedEmail");
                    secureLocalStorage.setItem("is_loggedin", "done");
                    secureLocalStorage.setItem("verified", true);
                    secureLocalStorage.setItem("integration_process", "not_completed");
                    const currentTime = new Date()
                    const twoHoursFromNow = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
                    secureLocalStorage.setItem('Expired', twoHoursFromNow.toString());
                    setVerifyEmail(false)
                    setTimeout(() => {
                        history.push('/integration/steps')
                    }, 1000)
                } else {
                    setError(true)
                    setVerifyEmail(false)
                    return;
                }
            })
            .catch((err) => {
                console.log(err);
                openNotification(
                    "error",
                    "Oops!",
                    "Something went wrong. Please try later!"
                );
            });
    };



    return (
        <div className="bg-dark login-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Verification</title>
            </Helmet>
            <div className='bg-left-auth d-flex flex-column align-items-center justify-content-center'>
                <Row className="w-100">
                    <Col lg="3"></Col>
                    <Col lg="6" className="text-center verification-screen">
                        {verifyEmail ? <><Loader />
                            <p className="text-gray font-style ff-light mt-4 text-center text-22">
                                Your email verification is being processed...
                            </p>
                        </> : error ? <div><p className="text-gray font-style ff-light mt-4 text-center text-22">Email verification failed!</p></div> : <><img src={require('../../../assets/img/Dashboard/check-mark.png')} className="img-fluid check-mark" />
                            <p className="text-gray font-style ff-light mt-4 text-center text-22">
                                Your email has been successfully verified.
                            </p>
                        </>}
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </div>
        </div>
    )
}

export default VerifyEmail