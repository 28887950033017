import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory, Link } from "react-router-dom";
import Loader from "components/Loader";
import secureLocalStorage from "react-secure-storage";
import { LoginSocialGoogle } from "reactjs-social-login";
import { Eye, EyeOff } from "react-feather";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import { Helmet } from "react-helmet";
import { AuthorizeShopify } from "components/AuthorizeShopify";
import Config from "../../../configs/config.json";
import { useDispatch } from "react-redux";
import BillingOptions from "../Dashboad/BillingOptions";
import { getProductList, getStarted } from "redux/actions";

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailVal, setEmailVal] = useState("ok");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [signupPasswordVisibility, setSignupPasswordVisibility] =
    useState(false);
  const [signupCPasswordVisibility, setSignupCPasswordVisibility] =
    useState(false);
  const [timezone, setTimezone] = useState("");
  const [billingPop, setBilPop] = useState("");

  const { control, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const Integration_Process = secureLocalStorage.getItem(
      "integration_process"
    );
    const user_logged = secureLocalStorage.getItem("is_loggedin");

    if (Integration_Process === "completed" && user_logged === "done") {
      //Change to Revert
      history.push("/");
    } else if (
      Integration_Process === "not_completed" &&
      user_logged === "done"
    ) {
      history.push("/integration/steps");
    }
  }, []);

  useEffect(() => {
    var userTimezone = moment.tz.guess();
    setTimezone(userTimezone);
    let newEmail = secureLocalStorage.getItem("email");
    setValue("email", newEmail, { shouldValidate: true });
    // setEmailVal(newEmail)
  }, []);

  //Signup
  const onSignupSubmit = (data) => {
    if (data.password.length < 8) {
      openNotification(
        "error",
        "Oops!",
        "Your password must be at least 8 characters!"
      );
      return false;
    } else if (data.password !== data.cpassword) {
      openNotification(
        "error",
        "Oops!",
        "Password and confirm password must be same!"
      );
      return false;
    }

    const params = new FormData();
    params.append("email", data.email);
    params.append("password", data.password);
    params.append("timezone", timezone);

    setButtonDisable(true);

    Service.post({
      url: "/signup/",
      body: params,
      formdata: true,
    })
      .then((response) => {
        setButtonDisable(false);

        if (response && response.status === true) {
          secureLocalStorage.setItem(
            "token",
            response && response.user_data && response.user_data.access_token
          );
          secureLocalStorage.setItem(
            "refreshToken",
            response && response.user_data && response.user_data.refresh_token
          );
          secureLocalStorage.setItem("is_loggedin", "done");
          secureLocalStorage.setItem("storedEmail", data.email);
          secureLocalStorage.setItem("first_register", true);
          secureLocalStorage.setItem("initialDashboard", true);
          secureLocalStorage.setItem("integration_process", "completed");
          // dispatch(getStarted(true));

          // setTimeout(() => {
          //   const params = {
          //     limit: 12,
          //     page: 1,
          //     keyword: "",
          //   };
          //   dispatch(getProductList(params));
          // }, 3000);

          // setTimeout(() => {
          //   secureLocalStorage.setItem("initialDashboard", false);
          // }, 4000);
          if (!response.user_data.plan_configured) {
            setBilPop(true);
          } else {
            secureLocalStorage.setItem("is_billed", true);
            history.push("/");
          }
          // history.push('/verification')
        } else {
          openNotification(
            "error",
            "Oops!",
            response && response.message && response.message
          );
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonDisable(false);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  return (
    <div className="bg-dark login-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Signup</title>
      </Helmet>
      <div className="bg-left-auth d-flex flex-column align-items-center justify-content-between">
        <div className="py-5">
          <a href={`${Config.HOME_URL}`}>
            <img
              src={require("../../../assets/img/Priya_AI/logo.png")}
              className="img-fluid logo-beta"
              width="155px"
            />
          </a>
        </div>
        <Row className="w-100">
          <Col lg="4"></Col>
          <Col lg="4">
            <h2 className="text-white mb-3 text-center ff-light mb-1">
              Create Your Account
            </h2>
            {/* <p className="text-gray ff-light mb-5 text-center">
              *Use your Shopify email if you have one
            </p> */}
            <Form
              onSubmit={handleSubmit(onSignupSubmit)}
              className="login-modal d-flex flex-column align-items-center justify-content-center"
            >
              <FormGroup>
                <Controller
                  id="email"
                  name="email"
                  type="email"
                  rules={{
                    required: "Please enter your email",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Enter valid email address",
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      invalid={formState.errors.email && true}
                      placeholder="Email"
                      disabled={true}
                      value={emailVal}
                      onChange={(e) => {
                        setEmailVal(e.target.value);
                      }}
                      {...field}
                      className="py-4"
                    />
                  )}
                />
                {formState.errors.email && (
                  <FormFeedback>{formState.errors.email.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="password-field">
                <Controller
                  id="password"
                  name="password"
                  type="password"
                  rules={{
                    required: "Please enter your password",
                  }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Create Password"
                        type={signupPasswordVisibility ? "text" : "password"}
                        {...field}
                        className="py-4"
                        invalid={formState.errors.password && true}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => {
                          setSignupPasswordVisibility(
                            !signupPasswordVisibility
                          );
                        }}
                      >
                        {signupPasswordVisibility ? (
                          <Eye size="14" color="gray" />
                        ) : (
                          <EyeOff size="14" color="gray" />
                        )}
                      </div>
                    </>
                  )}
                />
                {formState.errors.password && (
                  <FormFeedback>
                    {formState.errors.password.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="password-field">
                <Controller
                  id="cpassword"
                  name="cpassword"
                  type="cpassword"
                  rules={{
                    required: "Please enter your confirm password",
                  }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Confirm Password"
                        type={signupCPasswordVisibility ? "text" : "password"}
                        {...field}
                        className="py-4"
                        invalid={formState.errors.cpassword && true}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => {
                          setSignupCPasswordVisibility(
                            !signupCPasswordVisibility
                          );
                        }}
                      >
                        {signupCPasswordVisibility ? (
                          <Eye size="14" color="gray" />
                        ) : (
                          <EyeOff size="14" color="gray" />
                        )}
                      </div>
                    </>
                  )}
                />
                {formState.errors.cpassword && (
                  <FormFeedback>
                    {formState.errors.cpassword.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`sign-button btn-white d-flex align-items-center justify-content-center text-dark mt-3 ff-bold ${
                    buttonDisable ? "py-2" : "py-3"
                  }`}
                >
                  <span className="ff-bold">
                    {buttonDisable ? <Loader /> : "Sign Up"}
                  </span>
                </button>
              </div>
            </Form>
            <p className="text-gray ff-light mt-4 text-center">
              Already have an account?{" "}
              <span
                className="text-banner cursor-pointer ml-2"
                onClick={() => history.push("/login")}
              >
                Sign in here
              </span>
            </p>
            {/* <div className="d-flex align-items-center justify-content-center pt-3">
                            <img src={require('../../../assets/img/Dashboard/or-line.png')} className="img-fluid" />
                            <span className="text-gray ff-inter-regular px-2">or</span>
                            <img src={require('../../../assets/img/Dashboard/or-line.png')} className="img-fluid" />
                        </div>
                        <AuthorizeShopify />
                        <div className="d-flex align-items-center justify-content-center">
                            <LoginSocialGoogle
                                client_id="203064133092-v1m4200a7l46n7gui606r5n4sj2l1nnv.apps.googleusercontent.com"
                                scope='https://www.googleapis.com/auth/userinfo.email'
                                onResolve={({ provider, data }) => {
                                    const endpoint = `${Config.BASE_URL}/login-with-gmail/`
                                    const userTimezone = moment.tz.guess();
                                    const formData = new FormData();
                                    formData.append('access_token', data.access_token);
                                    formData.append('email', data.email);
                                    formData.append("timezone", userTimezone);

                                    var requestOptions = {
                                        method: 'POST',
                                        body: formData,
                                        redirect: 'follow'
                                    };

                                    fetch(endpoint, requestOptions)
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result && result.status === true) {
                                                secureLocalStorage.setItem(
                                                    "token",
                                                    result && result.user_data && result.user_data.access_token
                                                );
                                                secureLocalStorage.setItem(
                                                    "refreshToken",
                                                    result && result.user_data && result.user_data.refresh_token
                                                );
                                                secureLocalStorage.setItem(
                                                    "email",
                                                    result && result.user_data && result.user_data.email
                                                );
                                                secureLocalStorage.setItem("is_loggedin", "done");
                                                secureLocalStorage.setItem(
                                                    "integration_process",
                                                    result &&
                                                        result.user_data &&
                                                        result.user_data.configuration_status
                                                        ? "completed"
                                                        : "not_completed"
                                                );
                                                const currentTime = new Date()
                                                const twoHoursFromNow = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
                                                secureLocalStorage.setItem('Expired', twoHoursFromNow.toString());
                                                if (
                                                    result &&
                                                    result.user_data &&
                                                    result.user_data.configuration_status
                                                ) {
                                                    
                                                    history.push('/')
                                                } else {
                                                    history.push('/integration/steps')
                                                }
                                            } else {
                                                openNotification("error", "Oops!", result && result.error);
                                                return;
                                            }

                                        })
                                        .catch(error => console.log('error', error));
                                }}
                                onReject={({ error }) => {
                                    console.log(error);
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn-transparent d-flex align-items-center justify-content-center py-2 px-4 text-white mt-3 ff-inter-semi"
                                >
                                    <img
                                        src={
                                            require("../../../assets/img/Priya_AI/glogin.png")
                                                .default
                                        }
                                        className="img-fluid ff-inter-semi py-0"
                                    />
                                    <span className="text-14 ff-inter-semi ml-2">Sign in with Google</span>
                                </button>
                            </LoginSocialGoogle></div> */}
          </Col>
          <Col lg="4"></Col>
        </Row>
        <BillingOptions billingPop={billingPop} />
        <div className="text-center my-5">
          <footer className="ff-inter-regular text-gray">
            <p className="text-gray ff-light mt-4 text-center">
              By creating an account you agree to our
              <a
                href={`${Config.HOME_URL}/termsofuse`}
                target={"_blank"}
                className="text-banner cursor-pointer ml-2"
              >
                Terms of Use
              </a>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Signup;
