import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useHistory, Link } from "react-router-dom";
import Loader from "components/Loader";
import { LoginSocialGoogle } from "reactjs-social-login";
import secureLocalStorage from "react-secure-storage";
import { Eye, EyeOff } from "react-feather";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import Config from "../../../configs/config.json";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const uid = urlParams.get("uid");
  const token = urlParams.get("token");

  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [signupPasswordVisibility, setSignupPasswordVisibility] =
    useState(false);
  const [signupCPasswordVisibility, setSignupCPasswordVisibility] =
    useState(false);
  const [timezone, setTimezone] = useState("");

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  //Signup
  const onSubmit = (data) => {
    if (data.password.length < 8) {
      openNotification(
        "error",
        "Oops!",
        "Your password must be at least 8 characters!"
      );
      return false;
    } else if (data.password !== data.cpassword) {
      openNotification(
        "error",
        "Oops!",
        "Password and confirm password must be same!"
      );
      return false;
    }

    const params = new FormData();
    params.append("new_password", data.password);
    params.append("confirm_password", data.cpassword);

    setButtonDisable(true);

    Service.post({
      url: `/password-reset/?uid=${uid}&token=${token}`,
      body: params,
      formdata: true,
    })
      .then((response) => {
        setButtonDisable(false);
        if (response && response.status === true) {
          openNotification(
            "success",
            "Success!",
            "Password has been changed successfully."
          );
          history.push("/login");
        } else {
          openNotification(
            "error",
            "Oops!",
            response && response.message && response.message
          );
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonDisable(false);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  return (
    <div className="bg-dark login-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Reset Password</title>
      </Helmet>
      <div className="bg-left-auth d-flex flex-column align-items-center justify-content-between">
        <div className="py-5">
          <a href={`${Config.HOME_URL}`}>
            <img
              src={require("../../../assets/img/Priya_AI/logo.png")}
              className="img-fluid logo-beta"
              width="155px"
            />
          </a>
        </div>
        <Row className="w-100">
          <Col lg="4"></Col>
          <Col lg="4">
            <h2 className="text-white text-center ff-light mb-4">
              Reset Password
            </h2>

            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="login-modal d-flex flex-column align-items-center justify-content-center"
            >
              <FormGroup className="password-field">
                <Controller
                  id="password"
                  name="password"
                  type="password"
                  rules={{
                    required: "Please enter your password",
                  }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Password"
                        type={signupPasswordVisibility ? "text" : "password"}
                        {...field}
                        className="py-4"
                        invalid={formState.errors.password && true}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => {
                          setSignupPasswordVisibility(
                            !signupPasswordVisibility
                          );
                        }}
                      >
                        {signupPasswordVisibility ? (
                          <Eye size="14" color="gray" />
                        ) : (
                          <EyeOff size="14" color="gray" />
                        )}
                      </div>
                    </>
                  )}
                />
                {formState.errors.password && (
                  <FormFeedback>
                    {formState.errors.password.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="password-field">
                <Controller
                  id="cpassword"
                  name="cpassword"
                  type="cpassword"
                  rules={{
                    required: "Please enter your confirm password",
                  }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        placeholder="Confirm Password"
                        type={signupCPasswordVisibility ? "text" : "password"}
                        {...field}
                        className="py-4"
                        invalid={formState.errors.cpassword && true}
                      />
                      <div
                        className="password-toggle"
                        onClick={() => {
                          setSignupCPasswordVisibility(
                            !signupCPasswordVisibility
                          );
                        }}
                      >
                        {signupCPasswordVisibility ? (
                          <Eye size="14" color="gray" />
                        ) : (
                          <EyeOff size="14" color="gray" />
                        )}
                      </div>
                    </>
                  )}
                />
                {formState.errors.cpassword && (
                  <FormFeedback>
                    {formState.errors.cpassword.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <div className="mt-2 text-center">
                <button
                  type="submit"
                  className={`sign-button btn-white d-flex align-items-center justify-content-center text-dark mt-3 mr-sm-3 ff-bold ${
                    buttonDisable ? "py-2" : "py-3"
                  }`}
                >
                  <span className="ff-bold">
                    {buttonDisable ? <Loader /> : "Submit"}
                  </span>
                </button>
              </div>
              <p className="text-gray ff-light mt-4 text-center">
                Back to
                <span
                  className="text-banner cursor-pointer ml-2"
                  onClick={() => history.push("/login")}
                >
                  Login
                </span>
              </p>
            </Form>
          </Col>
          <Col lg="4"></Col>
        </Row>
        <div className="text-center">
          <footer className="ff-inter-regular position-abs3 text-gray">
            <a
              href={`${Config.HOME_URL}/privacypolicy`}
              target={"_blank"}
              className="text-gray"
            >
              Privacy Policy
            </a>{" "}
            <span className="px-2">|</span>{" "}
            <a
              href={`${Config.HOME_URL}/termsofuse`}
              target={"_blank"}
              className="text-gray"
            >
              Terms of Uses
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
