import React, { useEffect, useState } from 'react'
import "react-circular-progressbar/dist/styles.css";
import IndexNavbar from 'components/Navbars/IndexNavbar';
import { Helmet } from "react-helmet";
import { Service } from 'services/Service';
import Loader from 'components/Loader';

const ShopifyAuth = () => {

    const params = new URLSearchParams(window.location.search);
    const shopName = params.get('shop');

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        Service.get({
            url: `/shopify/auth/?shop=${shopName}`
        })
            .then(response => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    })

    return (
        <>
            <div className='bg-dark '><Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Installation</title>
            </Helmet>
                <div className={`bg-left-auth integration-modal`}>
                    <section className='py-0 nav-section'>
                        <IndexNavbar />
                    </section>

                    {(loading) && <div className='position-desktop'>
                        <div className='text-center'><Loader /></div>
                        <p className="text-gray font-style ff-light mt-4 text-center text-22">
                            Loading...
                        </p>
                    </div>}

                </div>
            </div>
        </>
    )
}

export default ShopifyAuth;