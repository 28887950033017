import React, { useState } from "react";
import { Menu, X } from "react-feather";
import { MdOutlineTune } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import {
  Collapse,
  Nav,
  NavItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logout from "components/Logout";
import Config from "../../configs/config.json";

export const MenuItems = () => {
  const history = useHistory();
  const location = useLocation();

  const [collapse, setCollapse] = useState(false);
  const [collapseSubmenu, setCollapseSubmenu] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleCollapse = () => setCollapse(!collapse);
  const toggleCollapseSubmenu = () => setCollapseSubmenu(!collapseSubmenu);

  const currentURL = window.location.href;

  const home_url = currentURL === `${Config.DASHBOARD_URL}/`;

  const namedLocations = {
    dashboard: "/",
    login: "/login",
    signup: "/signup",
    auth: "/auth",
    steps: "/integration/steps",
    settings: "/settings",
    paymentmethods: "/billing/payment-methods",
  };

  const findActiveItem = () => {
    const currentItem = Object.keys(namedLocations).find(
      (name) => namedLocations[name] === location.pathname
    );
    return currentItem;
  };

  return (
    <>
      <Nav vertical className="sidebar-nav">
        <div className="d-block d-xl-none">
          <Menu
            onClick={toggleCollapse}
            color="black"
            className="cursor-pointer ml-3 my-3 d-block d-xl-none"
            size={25}
          />
          <Collapse navbar isOpen={collapse}>
            <div className="d-flex justify-content-between">
              <div className="menu-items">
                <NavItem>
                  <Link
                    className={`d-flex align-items-center mt-xl-4 p-3 ${
                      findActiveItem() === "dashboard" &&
                      "menu-active rounded-lg"
                    }`}
                    to="/"
                  >
                    {findActiveItem() === "dashboard" ? (
                      <div className="mr-3">
                        <RxDashboard color="#00C4E9" size={25} />
                      </div>
                    ) : (
                      <img
                        src={require("../../assets/img/Dashboard/grid.png")}
                        width={20}
                        height={20}
                        className="mr-3"
                      />
                    )}
                    <h4
                      className={`mb-0 ff-inter-medium ${
                        findActiveItem() === "dashboard"
                          ? "text-banner"
                          : "text-white"
                      }`}
                    >
                      Dashboard
                    </h4>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className={`d-flex align-items-center my-2 my-xl-4 p-3 ${
                      window.location.href.indexOf("/billing") > -1 &&
                      "menu-active rounded-lg"
                    }`}
                    to="/billing/overview"
                  >
                    {window.location.href.includes("/billing") ? (
                      <img
                        src={require("../../assets/img/Dashboard/receipt.png")}
                        width={20}
                        height={20}
                        className="mr-3"
                      />
                    ) : (
                      <img
                        src={require("../../assets/img/Dashboard/billing.png")}
                        width={20}
                        height={20}
                        className="mr-3"
                      />
                    )}
                    <h4
                      className={`mb-0 ff-inter-medium ${
                        window.location.href.includes("/billing")
                          ? "text-banner"
                          : "text-white"
                      }`}
                    >
                      Billing
                    </h4>
                  </Link>
                  {/* <div className={`d-flex flex-column my-2 my-xl-4 p-3 cursor-pointer ${(window.location.href.indexOf('/billing')) > -1 && 'menu-active rounded-lg'}`}>
                                        <div className="d-flex align-items-center" onClick={toggleCollapseSubmenu}>
                                            {window.location.href.includes('/billing') ? <img src={require('../../assets/img/Dashboard/receipt.png')} width={20} height={20} className="mr-3" /> : <img src={require('../../assets/img/Dashboard/billing.png')} width={20} height={20} className="mr-3" />}
                                            <h4 className={`mb-0 ff-inter-medium ${window.location.href.includes('/billing') ? 'text-banner' : 'text-white'}`}>Billing</h4>
                                        </div>
                                        <Collapse isOpen={collapseSubmenu} className="ml-3 mt-2">
                                            <NavItem className="mt-2"><Link to='/billing/overview' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/overview') ? 'text-banner' : 'text-light-gray'}`}>Billing Overview</Link>
                                            </NavItem>

                                            //Commenting Out Billing Payment Method For now!
                                            <NavItem className="mt-2"><Link to='/billing/payment-methods' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/payment-methods') ? 'text-banner' : 'text-light-gray'}`}>Payment Methods</Link>
                                            </NavItem>
                                            <NavItem className="mt-2"><Link to='/billing/history' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/history') ? 'text-banner' : 'text-light-gray'}`}>Billing History</Link>
                                            </NavItem>
                                            <NavItem className="mt-2"><a href={`${Config.HOME_URL}/pricing`} target={'_blank'} className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/pricing') ? 'text-banner' : 'text-light-gray'}`}>Pricing</a>
                                            </NavItem>
                                            <NavItem className="mt-2"><Link to='/billing/setting' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/setting') ? 'text-banner' : 'text-light-gray'}`}>Settings</Link>
                                            </NavItem>
                                        </Collapse>
                                    </div> */}
                </NavItem>
                <NavItem>
                  <Link
                    className={`d-flex align-items-center my-2 my-xl-4 p-3 ${
                      window.location.href.indexOf("/settings") > -1 &&
                      "menu-active rounded-lg"
                    }`}
                    to="/settings"
                  >
                    {window.location.href.includes("/settings") ? (
                      <div className="mr-3">
                        <MdOutlineTune color="#00C4E9" size={25} />
                      </div>
                    ) : (
                      <img
                        src={require("../../assets/img/Dashboard/settings.png")}
                        width={20}
                        height={20}
                        className="mr-3"
                      />
                    )}
                    <h4
                      className={`mb-0 ff-inter-medium ${
                        window.location.href.includes("/settings")
                          ? "text-banner"
                          : "text-white"
                      }`}
                    >
                      Settings
                    </h4>
                  </Link>
                </NavItem>
                <NavItem onClick={() => setShowConfirmation(!showConfirmation)}>
                  <div className="d-flex align-items-center my-2 my-xl-2 p-3 cursor-pointer">
                    <img
                      src={require("../../assets/img/Dashboard/logout.png")}
                      width={20}
                      height={20}
                      className="mr-3"
                    />
                    <h4
                      className={`mb-0 ff-inter-medium ${
                        window.location.href.includes("/logout")
                          ? "text-banner"
                          : "text-white"
                      }`}
                    >
                      Logout
                    </h4>
                  </div>
                </NavItem>
              </div>
              <X
                color="white"
                className="cursor-pointer"
                onClick={toggleCollapse}
              />
            </div>
          </Collapse>
        </div>
        <div className="d-none d-xl-block">
          <NavItem
            className={
              findActiveItem() === "dashboard" &&
              "menu-active rounded-lg d-block"
            }
          >
            <Link
              className={`d-flex align-items-center mt-xl-4 p-3 ${
                findActiveItem() === "dashboard" && "menu-active rounded-lg"
              }`}
              to="/"
            >
              {findActiveItem() === "dashboard" ? (
                <img
                  src={require("../../assets/img/Dashboard/grid-dark.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              ) : (
                <img
                  src={require("../../assets/img/Dashboard/grid.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              )}
              <h4
                className={`mb-0 ff-inter-medium ${
                  findActiveItem() === "dashboard"
                    ? "text-dark"
                    : "text-light-gray"
                }`}
              >
                Dashboard
              </h4>
            </Link>
          </NavItem>
          <NavItem
            className={
              window.location.href.indexOf("/billing") > -1 &&
              "menu-active rounded-lg d-block"
            }
          >
            <Link
              className={`d-flex align-items-center p-3 ${
                window.location.href.indexOf("/billing") > -1 &&
                "menu-active rounded-lg"
              }`}
              to="/billing/overview"
            >
              {window.location.href.includes("/billing") ? (
                <img
                  src={require("../../assets/img/Dashboard/billing-dark.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              ) : (
                <img
                  src={require("../../assets/img/Dashboard/billing.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              )}
              <h4
                className={`mb-0 ff-inter-medium ${
                  window.location.href.indexOf("/billing") > -1
                    ? "text-dark"
                    : "text-light-gray"
                }`}
              >
                Billing
              </h4>
            </Link>
          </NavItem>
          {/* <NavItem >
                        <div className={`d-flex flex-column p-3 cursor-pointer ${(window.location.href.indexOf('/billing')) > -1 && 'menu-active rounded-lg'}`}>
                            <div className="d-flex align-items-center " onClick={toggleCollapseSubmenu}>
                                {window.location.href.includes('/billing') ? <img src={require('../../assets/img/Dashboard/billing-dark.png')} width={20} height={20} className="mr-3" /> : <img src={require('../../assets/img/Dashboard/billing.png')} width={20} height={20} className="mr-3" />}
                                <h4 className={`mb-0 ff-inter-medium ${window.location.href.indexOf('/billing') > -1 ? 'text-dark' : 'text-light-gray'}`}>Billing</h4>
                            </div>
                            <Collapse isOpen={collapseSubmenu} className="ml-3 mt-2">
                                <NavItem className="mt-2"><Link to='/billing/overview' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/overview') ? 'text-dark' : 'text-light-gray'}`}>Billing Overview</Link>
                                </NavItem>

                                // Commenting Our this For Payment Method 
                                <NavItem className="mt-2"><Link to='/billing/payment-methods' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/payment-methods') ? 'text-dark' : 'text-light-gray'}`}>Payment Methods</Link>
                                </NavItem>
                                <NavItem className="mt-2"><Link to='/billing/history' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/history') ? 'text-dark' : 'text-light-gray'}`}>Billing History</Link>
                                </NavItem>
                                <NavItem className="mt-2"><a href={`${Config.HOME_URL}/pricing`} target={'_blank'} className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/pricing') ? 'text-dark' : 'text-light-gray'}`}>Pricing</a>
                                </NavItem>
                                <NavItem className="mt-2"><Link to='/billing/setting' className={`ml-4 ff-inter-medium text-14 ${window.location.href.includes('/billing/setting') ? 'text-dark' : 'text-light-gray'}`}>Settings</Link>
                                </NavItem>
                            </Collapse>
                        </div>
                    </NavItem> */}
          <NavItem
            className={
              window.location.href.indexOf("/settings") > -1 &&
              "menu-active rounded-lg d-block"
            }
          >
            <Link
              className={`d-flex align-items-center p-3 ${
                window.location.href.indexOf("/settings") > -1 &&
                "menu-active rounded-lg"
              }`}
              to="/settings"
            >
              {window.location.href.includes("/settings") ? (
                <img
                  src={require("../../assets/img/Dashboard/settings-dark.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              ) : (
                <img
                  src={require("../../assets/img/Dashboard/settings.png")}
                  width={20}
                  height={20}
                  className="mr-3"
                />
              )}
              <h4
                className={`mb-0 ff-inter-medium ${
                  window.location.href.indexOf("/settings") > -1
                    ? "text-dark"
                    : "text-light-gray"
                }`}
              >
                Settings
              </h4>
            </Link>
          </NavItem>
          <NavItem onClick={() => setShowConfirmation(!showConfirmation)}>
            <div className="d-flex align-items-center p-3 cursor-pointer">
              <img
                src={require("../../assets/img/Dashboard/logout.png")}
                width={20}
                height={20}
                className="mr-3"
              />
              <h4
                className={`mb-0 ff-inter-medium ${
                  window.location.href.indexOf("/logout") > -1
                    ? "text-dark"
                    : "text-light-gray"
                }`}
              >
                Logout
              </h4>
            </div>
          </NavItem>
        </div>
      </Nav>

      <Logout
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
      />
    </>
  );
};
