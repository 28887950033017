import { MenuItems } from "components/MenuItems";
import Navbar from "components/NavbarDashboard";
import { Search } from "react-feather";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
} from "reactstrap";
import { GrStatusGoodSmall } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import { BsInfoCircle } from "react-icons/bs";
import BillingOptions from "./BillingOptions";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlineArrowDown,
} from "react-icons/ai";
import PieChart from "components/CircularProgressBar";
import PieGapChart from "components/CircularProgressBar/GapChart";
import PriceHistory from "./PriceHistory";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getProductList, getStarted } from "redux/actions";
import Loader from "components/Loader";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";
import SetupPaymentModal from "./SetupPaymentModal";
import Config from "../../../configs/config.json";
import { RotatingLines } from "react-loader-spinner";
import Logout from "components/Logout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentNotification from "components/PaymentNotification";

// const stripePromise = loadStripe(Config.STRIPE_KEY);

function Dashboard() {
  //Get data from redux store
  const { productList, isFetchProduct, isPageLoad, initialDashboard } =
    useSelector(({ modal }) => modal);

  secureLocalStorage.setItem("store_url", productList && productList.store_url);

  const dispatch = useDispatch();

  // States
  const [viewPopup, setViewPopup] = useState(false);
  const [index, setIndex] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFloorpopup, setOpenFloorpopup] = useState(false);
  const [openPriceHistory, setOpenPriceHistory] = useState(false);
  const [productData, setProductData] = useState([]);
  const [billingPop, setBilPop] = useState();
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [storeURL, setStoreURL] = useState("");
  const [storeStatus, setStoreStatus] = useState("");
  const [date, setDate] = useState(null);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [billingPopup, setBillingPopup] = useState(false);
  const [priceEfficiency, setPriceEfficiency] = useState({});
  const [priceUpdates, setPriceUpdates] = useState(false);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const [floorPrice, setFloorPrice] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enableConfirmation, setEnableConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [freeAPICalls, setFreeAPICalls] = useState(null);
  const [paymentAdded, setPaymentAdded] = useState(false);
  const [expiryPopup, setExpiryPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBillingOpen, setBillingIsOpen] = useState(false);
  const [progress, setProgress] = useState(0.3);
  const [startDate, setStartDate] = useState(new Date());

  //States toggle functions
  const toggleFloorPopup = () => {
    if (!openFloorpopup) {
      setFloorPrice(null);
    }
    setOpenFloorpopup(!openFloorpopup);
  };
  const toggleIntegration = () => setIsOpen(!isOpen);
  const toggleBillingIntegration = () => setBillingIsOpen(!isBillingOpen);

  const togglePriceHistory = () => setOpenPriceHistory(!openPriceHistory);

  // Call when store connected for the first time
  useEffect(() => {
    setTimeout(() => {
      dispatch(getStarted(false));
    }, 8000);
  }, []);

  // Get immediate price updates when store connected for the first time
  let timeInterval = 0;
  let timeAttempts = 0;
  let maxAttempts = 4;
  const PriceCheckStatus = () => {
    Service.get({
      url: `/immediate-price-update/check_status/`,
    })
      .then((response) => {
        if (
          response &&
          response.status &&
          response.data &&
          response.data.status === "Completed"
        ) {
          clearInterval(timeInterval);
          setPriceUpdates(false);
          const params = {
            limit: rowsPerPage,
            page: 1,
            keyword: "",
          };
          console.log("Calling the sync products");
          // Calling The Sync Products
          dispatch(getProductList(params));
        } else {
          if (timeAttempts < maxAttempts) {
            timeAttempts++;
            setPriceUpdates(true);
          } else {
            console.log("Max Attempts Reached");
            clearInterval(timeInterval);
            setPriceUpdates(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ImmediatePriceUpdate = () => {
    Service.get({
      url: `/immediate-price-update/`,
    })
      .then((response) => {
        if (response && response.status === true) {
          PriceCheckStatus();
          timeInterval = setInterval(() => {
            PriceCheckStatus();
          }, 30000);
          // let token = secureLocalStorage.getItem('token')
          // console.log("The Token is ",token)

          // // const ws = new WebSocket(`wss://api-staging.priya.ai/ws/price_updates/`,`bearer ${token}`);
          // const ws = new WebSocket(`wss://api-staging.priya.ai/ws/price_updates/?token=${encodeURIComponent(token)}`);
          // ws.onopen = () => {
          //   console.log("Connected to WebSocket");
          // };

          // ws.onmessage = (event) => {
          //   console.log("event", event);
          //   const data = JSON.parse(event.data);
          //   console.log("data", data);
          //   if (data.status === "Completed") {
          //     setPriceUpdates(false);
          //     const params = {
          //       limit: rowsPerPage,
          //       page: 1,
          //       keyword: "",
          //     };
          //     dispatch(getProductList(params));
          //   }
        }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   ImmediatePriceUpdate();
  // }, []);

  const RedirectToBilling = () => {
    Service.get({
      url: `/billing/update_capped_amount/`,
    })
      .then((response) => {
        if (response && response.status === true) {
          response.confirmation_url &&
            (window.location.href = response.confirmation_url);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (initialDashboard) {
      const params = {
        limit: rowsPerPage,
        page: 1,
        keyword: "",
      };
      dispatch(getProductList(params));
      setPriceUpdates(true);
      ImmediatePriceUpdate();
      secureLocalStorage.setItem("initialDashboard", false);
    } else {
      const params = {
        limit: rowsPerPage,
        page: currentPage,
        keyword: "",
        loading: true,
      };
      dispatch(getProductList(params, initialDashboard));
    }
  }, []);

  // Progress bar for price updates
  useEffect(() => {
    if (priceUpdates) {
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 90 ? prevProgress + 0.8 : 90
        );
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
      }, 180000);
    }
  }, []);

  const getPriceEfficiency = () => {
    setLoading(true);
    Service.get({
      url: `/products/${productInfo.id}/price_efficiency/`,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          setPriceEfficiency(response.product);
          if (response && response.product.efficiency_datetime !== null) {
            setDate(
              moment(response.product.efficiency_datetime).format(
                "MM/DD/YYYY hh:mma"
              )
            );
          } else {
            setDate(
              moment(Date().toLocaleString()).format("MM/DD/YYYY hh:mma")
            );
          }
        } else setPriceEfficiency({});
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (viewPopup) {
      getPriceEfficiency();
    }
  }, [viewPopup]);

  //   const RedirectToBill = (shopUrl) => {
  //     Service.get({
  //       url: `/billing/add_billing/?redirect=false&shop=${shopUrl}`,
  //       formdata: true,
  //     })
  //       .then((response) => {
  //         if (response && response.status === true) {
  //           if (response.confirmation_url) {
  //             window.location.href = response.confirmation_url;
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const RedirectToAuth = (shop_url) => {
    Service.get({
      url: `/shopify/auth/?shop=${shop_url}&redirect=false`,
    })
      .then((response) => {
        if (response && response.status === false) {
          // toggle()
          // setLoading(false)
          return;
        } else {
          // toggle()
          // setLoading(false)
          window.open(response.url, "_self");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // Get user payment added status
  const getPaymentAddedStatus = () => {
    Service.post({
      url: `/get-user-details/`,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          if (response.payment_method === false) {
            // console.log("Get User Details Payment Method");
            // RedirectToBill(response.store_url);
          }
          if (response.all_webhooks_configured === false) {
            RedirectToAuth(response.store_url);
          }
          if (response?.update_capped_amount) {
            setBilPop(true);
          }
          if (!response?.plan_id) {
            setBilPop(true);
          }
          if (response?.plan_id) {
            secureLocalStorage.setItem("plan_id", response?.plan_id);
          }
          setPaymentAdded(response.payment_method);
          setFreeAPICalls(
            response.remaining_api_credits &&
              response.remaining_api_credits
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          ); // Thousand separator
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPaymentAddedStatus();
  }, []);

  useEffect(() => {
    setIsLoading(isPageLoad);
  }, [isPageLoad]);

  //Set product data into state
  useEffect(() => {
    if (storeURL === "" || storeURL === undefined) {
      setStoreURL(productList && productList.store_url);
    }
    if (storeStatus === "" || storeStatus === undefined) {
      setStoreStatus(productList && productList.store_status);
    }
    if (
      productList &&
      productList.products &&
      productList.products.length > 0
    ) {
      setProductData(productList.products);
      setTotal(productList.total_products);
    } else {
      setProductData([]);
    }
  }, [productList]);

  const handleFloorPrice = (e) => {
    if (
      productList &&
      productList.products &&
      productList.products.length > 0
    ) {
      productList.products[index].floor_price = floorPrice;
    }

    setLoading(true);
    if (!/^\d*\.?\d+$/.test(floorPrice)) {
      openNotification(
        "error",
        "Oops!",
        "Floor price should be positive number!"
      );
      return;
    }
    const params = new FormData();
    params.append("floor_price", floorPrice);

    Service.put({
      url: `/products/${productInfo && productInfo.id}/`,
      body: params,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          openNotification(
            "success",
            "Success!",
            "Floor price updated successfully!"
          );
          setFloorPrice(null);
          //Fetch product list
          const params = {
            limit: rowsPerPage,
            page: currentPage,
            keyword: "",
          };
          toggleFloorPopup();
          setLoading(false);
        } else {
          setLoading(false);
          setFloorPrice(null);
          openNotification("error", "Oops!", response.message);
        }
      })
      .catch((err) => {
        toggleFloorPopup();
        setLoading(false);
        console.log(err);
      });
  };

  const handleRowToggle = (e) => {
    if (freeAPICalls === 0 && paymentAdded === false) {
      setExpiryPopup(!expiryPopup);
    } else {
      if (!e.target.checked) {
        setShowConfirmation(!showConfirmation);
      } else {
        setEnableConfirmation(!enableConfirmation);
      }
    }
  };

  // Enable/Disable products
  const handleConfirmation = () => {
    const params = new FormData();
    params.append(
      "status",
      productInfo && productInfo.status === "active" ? "inactive" : "active"
    );

    setLoading(true);

    Service.put({
      url: `/products/${productInfo && productInfo.id}/`,
      body: params,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          openNotification(
            "success",
            "Success!",
            `Product ${
              productInfo && productInfo.status === "active"
                ? "disabled"
                : "enabled"
            } successfully!`
          );
          setLoading(false);
          productInfo && productInfo.status === "active"
            ? setShowConfirmation(false)
            : setEnableConfirmation(false);

          //Fetch product list
          const params = {
            limit: rowsPerPage,
            page: currentPage,
            keyword: "",
          };
          dispatch(getProductList(params));
        } else {
          productInfo && productInfo.status === "active"
            ? setShowConfirmation(false)
            : setEnableConfirmation(false);
          setLoading(false);
          openNotification("error", "Oops!", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  const handlePagination = (page) => {
    const params = {
      limit: rowsPerPage,
      page: page.selected + 1,
      keyword: "",
    };
    dispatch(getProductList(params));
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(total / rowsPerPage));

    return (
      <div className="pagination-container">
        <ReactPaginate
          previousLabel={
            <>
              <div className="cursor-pointer d-flex align-items-center">
                <AiOutlineLeft size={15} color="#000" />
                <span className="ml-3 text-dark ff-inter-semi">Prev</span>
              </div>
            </>
          }
          nextLabel={
            <>
              <div className="cursor-pointer d-flex align-items-center">
                <span className="mr-3 text-dark ff-inter-semi">Next</span>{" "}
                <AiOutlineRight size={15} color="#000" />
              </div>
            </>
          }
          pageCount={count || 1}
          forcePage={currentPage === 1 ? 0 : currentPage - 1}
          onPageChange={(page) => handlePagination(page)}
          pageClassName={"page-item"}
          activeClassName="active"
          nextLinkClassName={"page-link"}
          nextClassName={"page-item next"}
          previousClassName={"page-item prev"}
          previousLinkClassName={"page-link"}
          pageLinkClassName={"page-link"}
          containerClassName={
            "pagination react-paginate justify-content-end my-2 pr-1"
          }
        />
      </div>
    );
  };

  //Search products

  const handleFilter = () => {
    const params = {
      limit: rowsPerPage,
      page: currentPage,
      keyword: searchTerm,
    };

    if (searchTerm !== "") {
      dispatch(getProductList(params));
    }
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  //Reset filter
  const handleReset = () => {
    setSearchTerm("");
    const params = {
      limit: rowsPerPage,
      page: 1,
      keyword: "",
    };
    dispatch(getProductList(params));
  };

  //     useEffect(()=>{
  // setViewPopup(true)
  //     },[])
  // Condition to showing payment notification bar

  //Undo this to show Popup
  // useEffect(() => {
  //     if (freeAPICalls === 0 && paymentAdded === false) {
  //         setPaymentPopup(true)
  //     } else {
  //         //Make it false
  //         setPaymentPopup(true)
  //     }
  // }, [paymentAdded, freeAPICalls])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const approveUpdates = () => {
    // setBillingPopup(false)
  };

  return (
    // <Elements stripe={stripePromise}>
    <div className="bg-white dashboard-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Dashboard</title>
      </Helmet>
      <Navbar />
      {initialDashboard ? (
        <div className="position-loading d-flex flex-column align-items-center justify-content-center mt-5">
          <Loader />{" "}
          <span className="mt-2 ff-inter-medium text-dark text-18 ml-3">
            Setting up your Dashboard...
          </span>
        </div>
      ) : isLoading ? (
        <div className="position-loading d-flex flex-column align-items-center justify-content-center mt-5">
          <Loader />{" "}
          <span className="mt-2 ff-inter-medium text-dark text-18 ml-3">
            Loading Products...
          </span>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column flex-xl-row project-section">
            <div className="d-flex align-items-center align-items-xl-start pr-0 menu-view">
              <div className="d-none d-xl-block">
                <MenuItems />
              </div>
              <span className="d-block d-sm-none ml-3 ff-inter-semi my-2 text-xl ">
                {window.location.href.indexOf("/dashboard") > -1
                  ? "Dashboard"
                  : window.location.href.indexOf("/billing") > -1
                  ? "Billing"
                  : window.location.href.indexOf("/settings") > -1
                  ? "Settings"
                  : window.location.href.indexOf("/logout") > -1
                  ? "Logout"
                  : ""}
              </span>
            </div>
            <div className="w-100 content-view py-3 p-xl-4">
              <div>
                <Row className="justify-content-between px-3 margin-custom">
                  <Col lg="6">
                    <div className="bg-card d-flex flex-column justify-content-between p-4 p-lg-5">
                      <div>
                        <h3 className="ff-inter-semi text-dark mb-0">
                          CONNECTED SITE
                        </h3>
                        <a className="text-blue ff-inter-semi">{storeURL}</a>
                      </div>
                      <div className="mt-5">
                        <h3 className="ff-inter-semi text-dark mb-0 text-uppercase">
                          Priya Connection Status
                        </h3>
                        <div>
                          <GrStatusGoodSmall color="green" />
                          <span className="ff-inter-regular ml-2 text-dark">
                            Active
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="mt-4 mt-lg-0">
                    <div className="bg-card p-3 d-flex justify-content-center">
                      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                        <div className="mr-sm-4">
                          <h3 className="ff-inter-semi text-dark mb-0 text-left pl-sm-5">
                            PRIYA LEARNING STATUS
                          </h3>
                        </div>
                        <div>
                          <div className="mt-4 mt-sm-0">
                            <div className="d-flex flex-column align-items-center">
                              <div className="d-flex justify-content-center position-rel mt-2 mt-sm-4">
                                <div>
                                  <PieGapChart status={storeStatus} />
                                </div>
                                <div className="d-flex align-items-start justify-content-start position-info">
                                  <BsInfoCircle
                                    color="#00C4E9"
                                    id="progess-info"
                                  />
                                </div>
                                <UncontrolledTooltip target="progess-info">
                                  <p className="ff-inter-medium text-gray">
                                    This is the current learning status of PRIYA
                                    for your connected website. The longer PRIYA
                                    is connected, the more optimal the pricing
                                    will start to become for all your connected
                                    products.
                                  </p>
                                </UncontrolledTooltip>
                              </div>
                              <div className="d-flex mt-4">
                                <div className="d-flex align-items-center justify-content-center mr-2">
                                  <GrStatusGoodSmall
                                    color={
                                      storeStatus === "Learning" ||
                                      storeStatus === null
                                        ? "#ffa800"
                                        : "#d6d6d6"
                                    }
                                  />
                                  <span
                                    className={`ff-inter-medium ml-1 text-12 ${
                                      storeStatus === "Learning" ||
                                      storeStatus === null
                                        ? "text-orange"
                                        : "text-muted"
                                    }`}
                                  >
                                    LEARNING
                                  </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mr-2">
                                  <GrStatusGoodSmall
                                    color={
                                      storeStatus === "Evaluating"
                                        ? "#fdd800"
                                        : "#d6d6d6"
                                    }
                                  />
                                  <span
                                    className={`ff-inter-medium ml-1 text-12 ${
                                      storeStatus === "Evaluating"
                                        ? "text-yellow"
                                        : "text-muted"
                                    }`}
                                  >
                                    EVALUATING
                                  </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                  <GrStatusGoodSmall
                                    color={
                                      storeStatus === "Optimizing"
                                        ? "#66c10e"
                                        : "#d6d6d6"
                                    }
                                  />
                                  <span
                                    className={`ff-inter-medium ml-1 text-12 ${
                                      storeStatus === "Optimizing"
                                        ? "text-green"
                                        : "text-muted"
                                    }`}
                                  >
                                    OPTIMIZING
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="my-4 d-flex flex-column flex-md-row justify-content-between px-3">
                  <div className="d-flex justify-content-between align-items-center mt-4 mt-md-0 order-2 order-md-1">
                    <h3 className="ff-inter-semi text-dark mb-0 mr-4">
                      Products
                    </h3>
                    <button
                      type="button"
                      className="px-4 py-1 view-btn"
                      onClick={handleReset}
                    >
                      <img
                        src={require("../../../assets/img/Dashboard/refresh.png")}
                        className="img-fluid cursor-pointer"
                        width={20}
                      />
                    </button>
                  </div>
                  <div className="d-flex align-items-center mb-sm-0 mb-1 mt-3 mt-md-0 dashboard-search order-1 order-md-2">
                    <InputGroup className="input-group-merge mb-0 mr-3">
                      <Input
                        id="search-products"
                        type="text"
                        placeholder="Search Products"
                        value={searchTerm}
                        onKeyPress={handleSearch}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="cursor-pointer search-bg">
                          <Search
                            size={18}
                            color="#999999"
                            onClick={handleFilter}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div className="mt-4 px-4">
                {isFetchProduct ? (
                  <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                    <Loader />{" "}
                    <span className="mt-2 ff-inter-medium text-dark text-18 ml-3">
                      Loading Products...
                    </span>
                  </div>
                ) : (
                  <>
                    {productData && productData.length > 0 ? (
                      <>
                        <Row className="products-row d-flex">
                          {productData.map((row, index) => (
                            <Col
                              xs="12"
                              sm="6"
                              lg="4"
                              xl="3"
                              className="mt-3 mt-sm-4 px-1"
                            >
                              <div
                                className={`px-3 py-2 ${
                                  row.status === "active"
                                    ? "bg-white"
                                    : "bg-gray"
                                } ${
                                  priceUpdates
                                    ? "bg-products-collecting"
                                    : "bg-products"
                                }`}
                              >
                                <div className="product-box">
                                  <Row className="d-flex align-items-start justify-content-star">
                                    <Col xs="4" sm="4" className="product-box">
                                      <div className="img-box">
                                        <img
                                          src={row.image_src}
                                          className="img-fluid img-box"
                                          alt="Product Image"
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="8" sm="8" className="pl-4">
                                      <p className="text-gray text-16 ff-inter-semi mb-0 text-capitalize">
                                        {row.product_type}
                                      </p>
                                      <div className="product-info">
                                        <h4 className="text-gray text-17 ff-inter-regular mt-2 mb-0 text-14">
                                          {row.title}
                                        </h4>
                                      </div>
                                      {priceUpdates && (
                                        <div>
                                          <div className="d-flex mt-5">
                                            <RotatingLines
                                              strokeColor="grey"
                                              strokeWidth="3"
                                              animationDuration="0.75"
                                              width="30"
                                              visible={true}
                                            />
                                            <span className="ml-2 ff-inter-semi text-16 text-gray">
                                              Collecting Data
                                            </span>
                                          </div>
                                          <div className="product-list mt-2">
                                            <Progress value={progress} />
                                          </div>
                                          <p className="mt-2 ff-inter-regular text-16 text-gray">
                                            3 minutes remaining
                                          </p>
                                        </div>
                                      )}
                                      {!priceUpdates && (
                                        <>
                                          <div className="mobileview-btn d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-2">
                                            <div className="current-price-box d-flex align-items-center my-1">
                                              <div style={{ width: "40px" }}>
                                                <img
                                                  src={require("../../../assets/img/Priya_AI/logo-success.png")}
                                                  className="img-fluid"
                                                  width={40}
                                                />
                                              </div>
                                              <div className="current-price d-flex flex-column ml-xl-2">
                                                <span className="ff-inter-semi text-16 text-gray">
                                                  Current Price
                                                </span>
                                                <h4 className="ff-inter-regular text-gray mb-0">
                                                  $
                                                  {row.current_price === null
                                                    ? row.price
                                                    : row.current_price.toFixed(
                                                        2
                                                      )}
                                                </h4>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mobileview-btn d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                                            <div className="current-price-box d-flex align-items-center my-1">
                                              <div
                                                className="ml-75 ml-xl-1 "
                                                style={{ width: "40px" }}
                                              >
                                                {row && row.sales_change < 0 ? (
                                                  <AiOutlineArrowDown
                                                    color="red"
                                                    size={28}
                                                  />
                                                ) : (
                                                  <AiOutlineArrowUp
                                                    color="green"
                                                    size={28}
                                                  />
                                                )}
                                              </div>
                                              <div className="current-price d-flex flex-column ml-xl-1">
                                                <span className="ff-inter-semi text-16 text-gray">
                                                  Sales Change (24hr)
                                                </span>
                                                <h4 className="ff-inter-regular text-gray mb-0">
                                                  {row.sales_change !== null
                                                    ? row.sales_change
                                                    : 0}
                                                  %
                                                </h4>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                                {!priceUpdates && (
                                  <Row className="mt-2 mt-xl-4">
                                    <Col
                                      xs="12"
                                      className="d-flex justify-content-between align-items-center my-2"
                                    >
                                      <button
                                        className="view-btn ff-inter-semi px-3 py-2 text-gray text-14 cursor-pointer"
                                        onClick={() => {
                                          setViewPopup(!viewPopup);
                                          setProductInfo(row);
                                          setIndex(index);
                                        }}
                                      >
                                        View Data
                                      </button>
                                      <CustomInput
                                        type="switch"
                                        id={`exampleCustomSwitch${
                                          row && row.id
                                        }`}
                                        name="customSwitch"
                                        inline
                                        className="cusor-pointer"
                                        checked={row && row.status === "active"}
                                        defaultChecked={
                                          row && row.status === "active"
                                        }
                                        onChange={(e) => {
                                          handleRowToggle(e, row);
                                          setProductInfo(row);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <div className="my-5">{CustomPagination()}</div>
                      </>
                    ) : (
                      <div className="text-dark text-center my-5 py-5">
                        No Products Found
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Disable PRIYA popup */}

            <Modal
              isOpen={showConfirmation}
              backdrop={true}
              toggle={() => setShowConfirmation(!showConfirmation)}
              className="modal-dialog-centered payment-modal"
            >
              <ModalHeader className="bg-header">
                <div>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo-black.png")}
                    className="img-fluid logo-mobile"
                    width={100}
                  />
                  <span className="ml-3 ff-inter-semi text-14">
                    DISABLE PRIYA
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setShowConfirmation(!showConfirmation)}
                >
                  <AiOutlineClose color="#000" size="15" />
                </div>
              </ModalHeader>
              <ModalBody className="my-4">
                <p className="ff-inter-medium text-dark mb-5 text-16">
                  Disabling PRIYA for any product will affect the learning
                  phase. Are you sure you'd like to continue?
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className={`ff-inter-semi py-2 text-gray button-gradient mr-2`}
                    onClick={() => handleConfirmation()}
                  >
                    {loading ? "Updating..." : "Yes"}
                  </button>
                  <button
                    className={`ff-inter-semi py-2 text-gray button-white ml-2`}
                    onClick={() => setShowConfirmation(!showConfirmation)}
                  >
                    No
                  </button>
                </div>
              </ModalBody>
            </Modal>

            {/* Enable PRIYA popup */}

            <Modal
              isOpen={enableConfirmation}
              backdrop={true}
              toggle={() => setEnableConfirmation(!enableConfirmation)}
              className="modal-dialog-centered payment-modal"
            >
              <ModalHeader className="bg-header">
                <div>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo-black.png")}
                    className="img-fluid logo-mobile"
                    width={100}
                  />
                  <span className="ml-3 ff-inter-semi text-14">
                    ENABLE PRIYA
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setEnableConfirmation(!enableConfirmation)}
                >
                  <AiOutlineClose color="#000" size="15" />
                </div>
              </ModalHeader>
              <ModalBody className="my-4">
                <p className="ff-inter-medium text-dark mb-5 text-16">
                  Are you sure you'd like to enable PRIYA?
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className={`ff-inter-semi py-2 text-gray button-gradient mr-2`}
                    onClick={() => handleConfirmation()}
                  >
                    {loading ? "Updating..." : "Yes"}
                  </button>
                  <button
                    className={`ff-inter-semi py-2 text-gray button-white ml-2`}
                    onClick={() => setEnableConfirmation(!enableConfirmation)}
                  >
                    No
                  </button>
                </div>
              </ModalBody>
            </Modal>

            {/* Set Floor Price */}

            <Modal
              isOpen={openFloorpopup}
              backdrop={true}
              toggle={toggleFloorPopup}
              className="modal-dialog-centered payment-modal"
            >
              <ModalHeader className="bg-header">
                <div>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo-black.png")}
                    className="img-fluid logo-mobile"
                    width={100}
                  />
                  <span className="ml-3 ff-inter-semi text-14">
                    SET FLOOR PRICE
                  </span>
                </div>
                <div className="cursor-pointer" onClick={toggleFloorPopup}>
                  <AiOutlineClose color="#000" size="15" />
                </div>
              </ModalHeader>
              <ModalBody className="my-4">
                <p className="ff-inter-medium text-dark mb-3 text-16">
                  You have the option to set a Floor Price for this product.
                  This will be the lowest price the product will ever reach.
                </p>
                <div className="mb-5 ">
                  <InputGroup className="input-group-merge floor-price mr-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="cursor-pointer search-bg">
                        {floorPrice === null ? (
                          <></>
                        ) : (
                          <div className="ff-inter-regular text-dark text-16">
                            $
                          </div>
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      min={0}
                      id="search-invoice"
                      type="number"
                      value={floorPrice}
                      placeholder="Enter Price ($)"
                      onChange={(e) => setFloorPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className={`ff-inter-semi py-2 text-gray button-gradient mr-2`}
                    onClick={() => {
                      handleFloorPrice();
                    }}
                  >
                    {floorPrice === null
                      ? "Save"
                      : `${loading ? "Updating.." : "Update"}`}
                  </button>
                  <button
                    className={`ff-inter-semi py-2 text-gray button-white ml-2`}
                    onClick={toggleFloorPopup}
                  >
                    Cancel
                  </button>
                </div>
              </ModalBody>
            </Modal>

            {/*View actions popup*/}

            <Modal
              isOpen={viewPopup}
              backdrop={true}
              toggle={() => setViewPopup(!viewPopup)}
              className="modal-dialog-centered payment-modal"
            >
              <ModalHeader className="bg-header">
                <div>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo-black.png")}
                    className="img-fluid logo-mobile"
                    width={100}
                  />
                  <span className="ml-3 ff-inter-semi text-14">
                    PRODUCT DATA
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setViewPopup(!viewPopup)}
                >
                  <AiOutlineClose color="#000" size="15" />
                </div>
              </ModalHeader>
              <ModalBody className="p-2">
                {loading ? (
                  <div className="text-center my-5">
                    <Loader />
                  </div>
                ) : (
                  <div className="mb-3">
                    <div className="d-flex align-items-center py-4">
                      <div>
                        <PieChart data={priceEfficiency.price_efficiency} />
                      </div>
                      <div className="d-flex flex-column ml-2">
                        <span className="ff-inter-semi text-gray">
                          Price Efficiency
                        </span>
                        <span className="ff-inter-semi text-gray">
                          {date}{" "}
                          <BsInfoCircle
                            color="#00C4E9"
                            id={`price-info${index}`}
                          />
                        </span>
                        <UncontrolledTooltip target={`price-info${index}`}>
                          <p className="ff-inter-medium text-gray">
                            Price efficiency represents how optimal the pricing
                            data is at the current time.
                          </p>
                        </UncontrolledTooltip>
                      </div>
                    </div>
                    <Row className="d-flex align-items-center mt-4">
                      <Col xs="6" sm="4" className="pr-0 text-sm-center">
                        <button
                          className="view-btn ff-inter-semi px-2 py-1 text-gray"
                          onClick={() => {
                            setViewPopup(!viewPopup);
                            togglePriceHistory();
                            setStartDate(new Date());
                          }}
                        >
                          View Price History
                        </button>
                      </Col>
                      <Col xs="6" sm="4" className="pr-0 text-sm-center">
                        <button
                          className="floor-btn ff-inter-semi px-3 py-1 text-gray"
                          onClick={() => {
                            toggleFloorPopup();
                            setFloorPrice(productInfo.floor_price);
                            setViewPopup(!viewPopup);
                          }}
                        >
                          Set Floor Price
                        </button>
                      </Col>
                      {/* <Col xs="6" sm="4" className='pr-0 mt-4 mt-sm-0 text-sm-center'>
                                            <button className='floor-btn ff-inter-semi px-3 py-1 text-gray' onClick={() => { handleUpdatePrice(productInfo) }}>{loading ? 'Updating...' : 'Update Price'}</button>
                                        </Col> */}
                    </Row>
                  </div>
                )}
              </ModalBody>
            </Modal>

            {/* Expiry API calls popup */}

            <Modal
              isOpen={expiryPopup}
              backdrop={true}
              toggle={() => setExpiryPopup(!expiryPopup)}
              className="modal-dialog-centered payment-modal"
            >
              <ModalHeader className="bg-header">
                <div>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo-black.png")}
                    className="img-fluid logo-mobile"
                    width={100}
                  />
                  <span className="ml-3 ff-inter-semi text-14">
                    EXPIRY CREDITS
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setExpiryPopup(!expiryPopup)}
                >
                  <AiOutlineClose color="#000" size="15" />
                </div>
              </ModalHeader>
              <ModalBody className="my-4">
                <p className="ff-inter-medium text-dark mb-5 text-16">
                  Your free credits have expired. Please add a payment method to
                  resume using PRIYA.
                </p>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  <button
                    className={`ff-inter-semi py-2 text-gray button-gradient mr-sm-2`}
                    onClick={() => toggleIntegration()}
                  >
                    Add Payment Method
                  </button>
                  <button
                    className={`ff-inter-semi py-2 text-gray button-white mt-3 mt-sm-0`}
                    onClick={() => setExpiryPopup(!expiryPopup)}
                  >
                    No
                  </button>
                </div>
              </ModalBody>
            </Modal>

            {/* Resume Payment popup */}

            <Modal
              isOpen={paymentPopup}
              backdrop={true}
              className="modal-dialog-centered payment-popup"
            >
              <ModalBody
                className="p-2 p-sm-5 text-center"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <a href={`${Config.HOME_URL}/`} target="_blank">
                  <img
                    src={require("../../../assets/img/Dashboard/logo-black.png")}
                    className="img-fluid mt-3 mt-sm-0 mb-4"
                    width={110}
                  />
                </a>
                <p className="expiry-text ff-inter-semi text-dark mb-0 text-18">
                  Your free credits have expired.
                </p>
                <p className="expiry-text ff-inter-semi text-dark text-18">
                  Please add a payment method to resume using PRIYA.
                </p>
                <div className="my-3">
                  <img
                    src={require("../../../assets/img/Dashboard/card_shadow.png")}
                    className="img-fluid"
                    width={200}
                  />
                </div>
                <p className="ff-inter-medium text-gray-50">
                  Keep PRIYA enabled so she can keep learning and improving your
                  pricing.
                </p>
                <div className="d-flex flex-column align-items-center mt-5">
                  <button
                    className={`w-fitcontent px-5 ff-inter-semi py-2 text-blue radius-xl button-gradient mr-2 text-16`}
                    onClick={() => {
                      toggleIntegration();
                      setPaymentPopup(false);
                    }}
                  >
                    Add Payment Method
                  </button>
                  <a
                    href={`${Config.HOME_URL}/pricing`}
                    target={"_blank"}
                    className="text-blue cursor-pointer mt-3 text-16"
                  >
                    Learn About Pricing
                  </a>
                  <p
                    className="text-blue cursor-pointer mb-3 mb-sm-0 mt-3 text-16"
                    onClick={() => {
                      setOpenLogoutPopup(!openLogoutPopup);
                    }}
                  >
                    Logout
                  </p>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={billingPopup}
              backdrop={true}
              className="modal-dialog-centered payment-popup"
            >
              <ModalBody
                className="p-2 p-sm-5 text-center"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <a href={`${Config.HOME_URL}/`} target="_blank">
                  <img
                    src={require("../../../assets/img/Dashboard/logo-black.png")}
                    className="img-fluid mt-3 mt-sm-0 mb-4"
                    width={110}
                  />
                </a>
                <p className="expiry-text ff-inter-semi text-dark mb-0 text-18">
                  You have added additional products to your store. Please
                  approve your updated billing amount to enable those products.
                </p>
                {/* <p className='expiry-text ff-inter-semi text-dark text-18'>Please add a payment method to resume using PRIYA.</p> */}
                <div className="my-3">
                  {/* <img src={require('../../../assets/img/Dashboard/card_shadow.png')} className="img-fluid" width={200} /> */}
                </div>
                {/* <p className='ff-inter-medium text-gray-50'>Keep PRIYA enabled so she can keep learning and improving your pricing.</p> */}
                <div className="d-flex flex-column align-items-center mt-5">
                  <button
                    className={`w-fitcontent px-5 ff-inter-semi py-2 text-blue radius-xl button-gradient mr-2 text-16`}
                    onClick={() => {
                      //   RedirectToBilling();
                    }}
                  >
                    Approve updates
                  </button>
                  {/* <a href={`${Config.HOME_URL}/pricing`} target={'_blank'} className='text-blue cursor-pointer mt-3 text-16'>Learn About Pricing</a>
                                <p className='text-blue cursor-pointer mb-3 mb-sm-0 mt-3 text-16' onClick={() => { setOpenLogoutPopup(!openLogoutPopup) }}>Logout</p> */}
                </div>
              </ModalBody>
            </Modal>

            <SetupPaymentModal
              isOpen={isOpen}
              toggle={toggleIntegration}
              setPaymentPopup={setPaymentPopup}
            />
            <PriceHistory
              isOpen={openPriceHistory}
              toggle={togglePriceHistory}
              data={productInfo}
              startDate={startDate}
              setStartDate={setStartDate}
            />
            <Logout
              showConfirmation={openLogoutPopup}
              setShowConfirmation={setOpenLogoutPopup}
            />
          </div>
        </div>
      )}
      <BillingOptions billingPop={billingPop} />
    </div>
    // </Elements>
  );
}

export default Dashboard;
