import { Fragment } from 'react'
import Avatar from 'components/avatar'
import { CheckCircle, X } from 'react-feather'
import { toast } from 'react-toastify'

const capitalizeFirstWord = (sentence) => {
    if (typeof sentence === 'string' && sentence.length > 0) {
        const words = sentence.split(' ');
        if (words.length > 0) {
            words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        }
        return words.join(' ');
    }
    return sentence;
};

const OpenToast = ({ color, title, message }) => (

    <Fragment>
        <div className='toastify-header'>
            <div className='d-flex align-items-center'>
                <h6 className={`text-capitalize ff-inter-regular text-16 mb-0 ${color === 'danger' ? 'text-danger' : 'text-success'}`}>{title}</h6>
            </div>
        </div>
        <div className='toastify-body ff-inter-regular mt-2'>
            <span>{capitalizeFirstWord(message)}</span>
        </div>
    </Fragment>
)

const openNotification = (type, title, message) => {
    const customId = "custom-id-yes"
    toast[type](<OpenToast color={(type === 'error') ? 'danger' : type} title={title} message={message} />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true.valueOf,
        toastId: customId

    })
}

const formatPhoneNumber = (value) => {

    if (!value) return value

    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber

    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export { openNotification, formatPhoneNumber }