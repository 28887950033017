import { MenuItems } from 'components/MenuItems'
import Navbar from 'components/NavbarDashboard'
import React, { } from 'react'
import { Row, Col, Label, Input, Form, FormGroup, FormFeedback } from 'reactstrap'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import PaymentNotification from 'components/PaymentNotification'


function BillingSettings() {

    const {
        control,
        handleSubmit,
        formState
    } = useForm({ mode: 'onChange' })

    const onSubmit = (data) => {
    }
    const statesArray = [
        { value: "Alabama", label: "Alabama" },
        { value: "Alaska", label: "Alaska" },
        { value: "Arizona", label: "Arizona" },
        { value: "Arkansas", label: "Arkansas" },
        { value: "California", label: "California" },
        { value: "Colorado", label: "Colorado" },
        { value: "Connecticut", label: "Connecticut" },
        { value: "Delaware", label: "Delaware" },
        { value: "Florida", label: "Florida" },
        { value: "Georgia", label: "Georgia" },
        { value: "Hawaii", label: "Hawaii" },
        { value: "Idaho", label: "Idaho" },
        { value: "Illinois", label: "Illinois" },
        { value: "Indiana", label: "Indiana" },
        { value: "Iowa", label: "Iowa" },
        { value: "Kansas", label: "Kansas" },
        { value: "Kentucky", label: "Kentucky" },
        { value: "Louisiana", label: "Louisiana" },
        { value: "Maine", label: "Maine" },
        { value: "Maryland", label: "Maryland" },
        { value: "Massachusetts", label: "Massachusetts" },
        { value: "Michigan", label: "Michigan" },
        { value: "Minnesota", label: "Minnesota" },
        { value: "Mississippi", label: "Mississippi" },
        { value: "Missouri", label: "Missouri" },
        { value: "Montana", label: "Montana" },
        { value: "Nebraska", label: "Nebraska" },
        { value: "Nevada", label: "Nevada" },
        { value: "New Hampshire", label: "New Hampshire" },
        { value: "New Jersey", label: "New Jersey" },
        { value: "New Mexico", label: "New Mexico" },
        { value: "New York", label: "New York" },
        { value: "North Carolina", label: "North Carolina" },
        { value: "North Dakota", label: "North Dakota" },
        { value: "Ohio", label: "Ohio" },
        { value: "Oklahoma", label: "Oklahoma" },
        { value: "Oregon", label: "Oregon" },
        { value: "Pennsylvania", label: "Pennsylvania" },
        { value: "Rhode Island", label: "Rhode Island" },
        { value: "South Carolina", label: "South Carolina" },
        { value: "South Dakota", label: "South Dakota" },
        { value: "Tennessee", label: "Tennessee" },
        { value: "Texas", label: "Texas" },
        { value: "Utah", label: "Utah" },
        { value: "Vermont", label: "Vermont" },
        { value: "Virginia", label: "Virginia" },
        { value: "Washington", label: "Washington" },
        { value: "West Virginia", label: "West Virginia" },
        { value: "Wisconsin", label: "Wisconsin" },
        { value: "Wyoming", label: "Wyoming" }

    ];

    return (
        <div className='bg-white dashboard-page'>
            <Navbar />
            {/* <PaymentNotification /> */}
            <div>
                <div className="d-flex flex-column flex-xl-row project-section">
                    <div className="d-flex align-items-center align-items-xl-start pr-0 menu-view">
                        <div className='d-none d-xl-block'>
                            <MenuItems />
                        </div>
                        <span className='d-block d-sm-none ml-3 ff-inter-semi my-2 text-xl '>{(window.location.href.indexOf('/dashboard')) > -1 ? 'Dashboard' : (window.location.href.indexOf('/billing')) > -1 ? 'Billing' : (window.location.href.indexOf('/settings')) > -1 ? 'Settings' : (window.location.href.indexOf('/logout')) > -1 ? 'Logout' : ''}</span>
                    </div>
                    <div className="w-100 content-view payment-screen height-bill-settings p-3 p-xl-4">
                        <div className='bg-white payment-card billing-settings p-3'>
                            <div className='settings-screen '>
                                <h3 className='ff-inter-semi text-dark'>Settings</h3>
                                <p className='ff-inter-medium text-gray text-16'>Changes to these settings will apply to future invoices only. If you need a past invoice reissued, please contact info@priya.ai</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='mt-4'>
                                        <FormGroup>
                                            <Label className='text-darkgray ff-inter-semi text-17'>Company Name</Label>
                                            <p className='ff-inter-medium text-gray text-14 mb-3'>If specified, this name will appear on invoices insted of your organisation name</p>

                                            <Controller
                                                id='companyName'
                                                name='companyName'
                                                type='text'
                                                control={control}
                                                render={({ field }) => <Input
                                                    invalid={formState.errors.companyName && true}
                                                    placeholder='David Grey'{...field}
                                                    className="py-4" />}
                                            />
                                            {formState.errors.companyName && <FormFeedback>{formState.errors.companyName.message}</FormFeedback>}
                                        </FormGroup>
                                    </div>
                                    <div className='mt-3'>
                                        <FormGroup>
                                            <Label className='text-darkgray ff-inter-semi text-17'>Purchase order (PO) number</Label>
                                            <p className='ff-inter-medium text-gray text-14 mb-3'>Your PO number will be displayed on future invoices.</p>
                                            <Controller
                                                id='poNumber'
                                                name='poNumber'
                                                type='text'
                                                control={control}
                                                render={({ field }) => <Input
                                                    invalid={formState.errors.poNumber && true}
                                                    {...field}
                                                    className="py-4" />}
                                            />
                                            {formState.errors.poNumber && <FormFeedback>{formState.errors.poNumber.message}</FormFeedback>}
                                        </FormGroup>
                                    </div>
                                    <div className='mt-3'>
                                        <FormGroup>
                                            <Label className='text-darkgray ff-inter-semi text-17'>Billing email</Label>
                                            <p className='ff-inter-medium text-gray text-14 mb-3'>Invoices and other billing notifications will be sent here (in addition to being sent to the owners of your organization).</p>
                                            <Controller
                                                id='billingEmail'
                                                name='billingEmail'
                                                type='email'
                                                control={control}
                                                render={({ field }) => <Input
                                                    invalid={formState.errors.billingEmail && true}
                                                    {...field}
                                                    className="py-4" />}
                                            />
                                            {formState.errors.billingEmail && <FormFeedback>{formState.errors.billingEmail.message}</FormFeedback>}
                                        </FormGroup>
                                    </div>
                                    <div className='mt-3'>
                                        <FormGroup>
                                            <Label className='text-darkgray ff-inter-semi text-17'>Primary business address</Label>
                                            <p className='ff-inter-medium text-gray text-14 mb-3'>This is the physical address of the company purchasing PRIYA services and is used to calculate any applicable sales taxes.</p>
                                            <Controller
                                                id='country'
                                                name='country'
                                                type='email'
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        invalid={formState.errors.country && true}
                                                        placeholder="Country"
                                                        classNamePrefix="react-select"
                                                        options={[{ value: 'US', label: 'United States' }]}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            {formState.errors.country && <FormFeedback>{formState.errors.country.message}</FormFeedback>}

                                        </FormGroup>
                                        <FormGroup>
                                            <Controller
                                                id='address1'
                                                name='address1'
                                                type='text'
                                                control={control}
                                                render={({ field }) => <Input
                                                    placeholder='Address line 1'
                                                    invalid={formState.errors.address1 && true}
                                                    {...field}
                                                    className="mt-1" />}
                                            />
                                            {formState.errors.address1 && <FormFeedback>{formState.errors.address1.message}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Controller
                                                id='address2'
                                                name='address2'
                                                type='text'
                                                control={control}
                                                render={({ field }) => <Input
                                                    placeholder='Address line 2'
                                                    invalid={formState.errors.address2 && true}
                                                    {...field}
                                                    className="mt-1" />}
                                            />
                                            {formState.errors.address2 && <FormFeedback>{formState.errors.address2.message}</FormFeedback>}
                                        </FormGroup>
                                        <div className='d-flex billing-form'>
                                            <FormGroup className='mr-1'>
                                                <Controller
                                                    id='city'
                                                    name='city'
                                                    type='text'
                                                    control={control}
                                                    render={({ field }) => <Input
                                                        placeholder='City'
                                                        invalid={formState.errors.city && true}
                                                        {...field}
                                                        className='mt-1' />}
                                                />
                                                {formState.errors.city && <FormFeedback>{formState.errors.city.message}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Controller
                                                    id='postalCode'
                                                    name='postalCode'
                                                    type='text'
                                                    control={control}
                                                    render={({ field }) => <Input
                                                        placeholder='Postal Code'
                                                        invalid={formState.errors.postalCode && true}
                                                        {...field}
                                                        className='mt-1 mr-1' />}
                                                />
                                                {formState.errors.postalCode && <FormFeedback>{formState.errors.postalCode.message}</FormFeedback>}
                                            </FormGroup>
                                        </div>
                                        <FormGroup>
                                            <Controller
                                                id='state'
                                                name='state'
                                                type='text'
                                                control={control}
                                                render={({ field }) => <Select
                                                    invalid={formState.errors.country && true}
                                                    placeholder="State"
                                                    classNamePrefix="react-select"
                                                    options={statesArray}
                                                    {...field}
                                                />}
                                            />
                                            {formState.errors.state && <FormFeedback>{formState.errors.state.message}</FormFeedback>}
                                        </FormGroup>

                                    </div>
                                    {/* <div className='mt-4'>
                                        <Label className='text-darkgray ff-inter-semi text-17 mb-0'>Business tax ID</Label>
                                        <p className='ff-inter-medium text-gray text-14 mb-3'>If you are a business tax registrant, please enter your business tax ID here</p>
                                    </div>
                                    <Row className='d-flex'>
                                        <Col xs="6" sm="5" className='pr-0'>
                                            <FormGroup>
                                                <Controller
                                                    id='type'
                                                    name='type'
                                                    control={control}
                                                    render={({ field }) => <Select
                                                        placeholder='Select Type'
                                                        invalid={formState.errors.type && true}
                                                        {...field}
                                                        classNamePrefix="react-select" />}
                                                />
                                                {formState.errors.type && <FormFeedback>{formState.errors.type.message}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="7">
                                            <FormGroup>
                                                <Controller
                                                    id='taxID'
                                                    name='taxID'
                                                    type='text'
                                                    control={control}
                                                    render={({ field }) => <Input
                                                        invalid={formState.errors.taxID && true}
                                                        {...field}
                                                        className='mt-1' />}
                                                />
                                                {formState.errors.taxID && <FormFeedback>{formState.errors.taxID.message}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                    <button type='submit' className='text-18 ff-inter-semi w-25 mt-4 btn-gray py-250'>Save</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingSettings