import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import {
    Col,
    Row
} from 'reactstrap';
import { useDispatch } from 'react-redux'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientBillingSVG from 'components/CircularProgressBar/gradientBillingSVG';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import { Helmet } from "react-helmet";

const Error = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const messageParam = searchParams.get('message');

        if (messageParam) {
            setErrorMessage(decodeURIComponent(messageParam));
        }
    }, []);

    return (
        <>
            <div className='bg-dark '><Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Installation</title>
            </Helmet>
                <div className='bg-left-auth integration-modal'>
                    <section className='py-0 nav-section'>
                        <IndexNavbar />
                    </section>

                    <div className='mx-3 mt-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-center'><div className='d-flex flex-column align-items-center justify-content-between step-success px-2 py-3 px-sm-5'>
                        <img src={require('../../assets/img/Priya_AI/logo-error.png')} className="img-fluid mt-3 mb-5 mb-lg-0" />
                        <div className='text-center'>
                            <p className='text-dark mb-0 ff-medium'>Connection Failed!</p>
                            <p className='text-gray mb-0 ff-inter-regular'>Please make sure PRIYA is connected properly.</p>
                            <p className='text-red mt-3 ff-inter-regular'>{errorMessage !== '' ? errorMessage : 'Contact support@priya.ai'}</p>
                        </div>
                        <p className='bottom-0 text-blue ff-inter-regular border-top pt-2 cursor-pointer mt-5 mt-lg-0' onClick={() => history.push('/auth')}>Try Again</p>
                    </div></div>
                </div>
            </div>
        </>
    )
}

export default Error;