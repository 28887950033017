import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import Config from "../../../configs/config.json";
import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";
import Logout from "components/Logout";
import { AuthorizeShopify } from "components/AuthorizeShopify";

const LoginScreen = () => {
  useEffect(() => {
    const Integration_Process = secureLocalStorage.getItem(
      "integration_process"
    );
    const user_logged = secureLocalStorage.getItem("is_loggedin");

    if (Integration_Process === "completed" && user_logged === "done") {
      history.push("/");
    }
  }, []);

  const history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const openLogin = () => {
    history.push("/login");
  };

  const openSignup = () => {
    history.push("/signup");
  };

  const Integration_Process = secureLocalStorage.getItem("integration_process");
  const user_logged = secureLocalStorage.getItem("is_loggedin");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Auth</title>
      </Helmet>
      <div className="bg-dark login-screen d-flex flex-column justify-content-between">
        <Row>
          <Col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            xs="12"
            className="pr-0 d-none d-md-block"
          >
            <div className="bg-left-auth d-flex flex-column justify-content-center">
              <section className="py-0">
                <a href={`${Config.HOME_URL}`}>
                  <img
                    src={require("../../../assets/img/Priya_AI/logo.png")}
                    className="img-fluid position-logo logo-beta"
                    width={155}
                  />
                </a>
              </section>
              <div className="text-center">
                <img
                  src={require("../../../assets/img/Dashboard/dashboard-main.png")}
                  className="img-fluid px-5"
                />
              </div>
            </div>
          </Col>
          <Col
            xl="5"
            lg="5"
            md="5"
            sm="12"
            xs="12"
            className="bg-auth d-flex flex-column justify-content-center align-items-center"
          >
            <div className="text-center d-md-none px-4 px-md-0">
              <a href={`${Config.HOME_URL}`}>
                <img
                  src={require("../../../assets/img/Priya_AI/logo.png")}
                  className="img-fluid position-logo-mobile logo-beta"
                  width="155px"
                />
              </a>
            </div>
            <div className="p-1 my-3">
              <h1 className="text-white ff-inter-regular text-center">
                Get Started
              </h1>
              <div className="mt-3">
                <AuthorizeShopify />
              </div>

              {/* <div className="d-flex align-items-center justify-content-center pt-3">
                                <img src={require('../../../assets/img/Dashboard/or-line.png')} className="img-fluid" />
                                <span className="text-gray ff-inter-regular px-2">or</span>
                                <img src={require('../../../assets/img/Dashboard/or-line.png')} className="img-fluid" />
                            </div>  */}

              <div className=" d-flex flex-column flex-sm-row justify-content-center align-items-center login-modal">
                {user_logged !== "done" && (
                  <>
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      // className=" btn-white d-flex align-items-center justify-content-center py-2 text-dark mt-3 ff-bold"
                      className="sign-button btn-white d-flex align-items-center justify-content-center text-dark mt-3 ff-bold py-3"
                      // className="sign-button btn-transparent d-flex align-items-center justify-content-center py-3 text-white mt-3 ff-bold"
                      onClick={openLogin}
                    >
                      <span className="ff-bold">Sign In</span>
                    </button>
                    {/* <button
                                        type="button"
                                        className="sign-button btn-transparent d-flex align-items-center justify-content-center py-3 text-white mt-3 ff-bold" onClick={openSignup}
                                    ><span className="ff-bold">Sign Up</span>
                                    </button>  */}
                  </>
                )}
                {Integration_Process === "not_completed" &&
                  user_logged === "done" && (
                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                      <button
                        type="button"
                        className="continue-btn btn-white d-flex align-items-center justify-content-center p-3 text-dark mt-3 ff-bold"
                        onClick={() => history.push("/integration/steps")}
                      >
                        <span className="ff-bold">Continue Integration</span>
                      </button>
                      <button
                        type="button"
                        className="continue-btn btn-transparent text-blue d-flex align-items-center justify-content-center ml-sm-3 py-3 px-4 text-dark mt-3 ff-bold"
                        onClick={() => setShowConfirmation(!showConfirmation)}
                      >
                        <span className="ff-bold">Logout</span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <footer className="ff-inter-regular text-center position-abs3 text-gray">
              <a href={`${Config.HOME_URL}`} className="text-gray">
                Back to Home
              </a>{" "}
              <span className="px-2">|</span>{" "}
              <a
                href={`${Config.HOME_URL}/termsofuse`}
                target={"_blank"}
                className="text-blue"
              >
                Terms of Use
              </a>
            </footer>
          </Col>
        </Row>
        <Logout
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
        />
      </div>
    </>
  );
};

export default LoginScreen;
