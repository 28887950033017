import Loader from "components/Loader";
import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  ModalHeader,
} from "reactstrap";
import Config from "../../configs/config.json";

const StepOne = ({
  setSelectStore,
  hoverEffectStepOne,
  setHoverEffectStepOne,
  clickEffectStepOne,
  setClickEffectStepOne,
  onClickVerify,
  errorMessage,
}) => {
  const [connectShopify, setConnectShopify] = useState(false);
  const [value, setValue] = useState("");
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [message, setMessage] = useState("Please enter your shop URL");

  useEffect(() => {
    if (errorMessage) {
      setMessage(errorMessage);
    }
  }, [errorMessage]);
  const toggle = () => setConnectShopify(!connectShopify);

  function scrollToBottom() {
    document.getElementById("continue-btn").scrollIntoView({
      behavior: "smooth",
      top: 0,
    });
  }

  const handleChange = (e) => {
    setDomain(e.target.value + ".myshopify.com");
    setValue(e.target.value);
    setValidate(false);
  };

  return (
    <>
      <Row className="center-content px-3">
        <Col lg="1"></Col>
        <Col lg="10" className="text-center mt-4 mt-xl-0">
          <p className="text-white mt-4 mb-0 ff-inter-regular">
            Follow the instructions below to connect PRIYA to your website.
          </p>
          <p className="text-white ff-inter-regular mt-3 mt-lg-0">
            PRIYA is free to use up to 20,000 API calls, then{" "}
            <a
              href={`${Config.HOME_URL}/pricing`}
              target={"_blank"}
              className="text-blue cursor-pointer"
            >
              pay as you go
            </a>
            .
          </p>
          <p style={{ color: "#999999" }} className="ff-inter-regular mt-4">
            Select your installation method:
          </p>
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-4">
            <div
              onClick={() => {
                setClickEffectStepOne(1);
                setSelectStore("shopify");
                toggle();
              }}
              onMouseOver={() => {
                setHoverEffectStepOne(1);
              }}
              onMouseOut={() => {
                setHoverEffectStepOne(null);
              }}
              className="cursor-pointer w-fitcontent "
            >
              {hoverEffectStepOne === 1 || clickEffectStepOne === 1 ? (
                <img
                  src={require("../../assets/img/Priya_AI/hover-img1.png")}
                  className="img-fluid p-0 mr-sm-5 box-shadow-shopify"
                  width={300}
                  height={100}
                />
              ) : (
                <img
                  src={require("../../assets/img/Priya_AI/step1-img1.png")}
                  className="img-fluid p-0 mr-sm-5 box-shadow-code"
                  width={300}
                  height={100}
                />
              )}
            </div>
            <div className="position-rel box-shadow-code mt-4 mt-sm-0 ml-sm-4">
              <div
                onClick={() => {
                  setClickEffectStepOne(2);
                  setSelectStore("copy-code");
                  scrollToBottom();
                }}
                onMouseOver={() => {
                  setHoverEffectStepOne(2);
                }}
                onMouseOut={() => {
                  setHoverEffectStepOne(null);
                }}
                className="cursor-pointer w-fitcontent bg-hide-code"
              >
                {hoverEffectStepOne === 2 || clickEffectStepOne === 2 ? (
                  <img
                    src={require("../../assets/img/Priya_AI/code-hover.png")}
                    className="img-fluid p-0"
                    width={300}
                    height={100}
                  />
                ) : (
                  <img
                    src={require("../../assets/img/Priya_AI/step1-img2.png")}
                    className="img-fluid p-0"
                    width={300}
                    height={100}
                  />
                )}
              </div>
              <div
                style={{ color: "#999999" }}
                className="ff-light position-coming-soon-code"
              >
                COMING SOON
              </div>
            </div>
          </div>
          <p className="mt-5">
            <a
              href="mailto: support@priya.ai"
              target={"_blank"}
              className="text-blue ff-inter-regular cursor-pointer"
            >
              Need help? Contact our support team now.
            </a>
          </p>
        </Col>
        <Col lg="1"></Col>
      </Row>

      <Modal
        isOpen={connectShopify}
        toggle={toggle}
        backdrop={true}
        size="lg"
        className="modal-dialog-centered payment-popup shopify-popup"
      >
        <ModalHeader className="py-4" style={{ backgroundColor: "#f4f4f4" }}>
          <div className="text-center mt-3">
            <a href={`${Config.HOME_URL}/`} target="_blank">
              <img
                src={require("../../assets/img/Priya_AI/logo-black.png")}
                className="img-fluid ml-4 logo-shopify-mobile"
                width={145}
              />
            </a>
          </div>
          <div className="close-btn">
            <FaTimes size={15} onClick={toggle} className="cursor-pointer" />
          </div>
        </ModalHeader>
        <ModalBody
          className="p-2 p-sm-4"
          style={{ backgroundColor: "#f4f4f4" }}
        >
          <div className="position-rel">
            <div className={`${loading ? "bg-hide" : ""}`}>
              <Form className="my-4 text-center">
                <div className="shopify-popup">
                  <FormGroup>
                    <h3 className="text-gray-shopify ff-inter-semi mb-0">
                      Enter Your Shopify Store Domain
                    </h3>
                    <p className="text-gray ff-inter-semi text-16 mb-3">
                      (Input the .myshopify.com one, not your public domain)
                    </p>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <InputGroup>
                        <Input
                          type="text"
                          onChange={handleChange}
                          className="ff-inter-medium"
                        />
                        <InputGroupText className="ff-inter-medium">
                          .myshopify.com
                        </InputGroupText>
                      </InputGroup>
                      {validate && (
                        <>
                          <p className="text-red text-14 ff-inter-regular">
                            {message}
                          </p>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="mt-4">
                  <button
                    className={`text-blue text-16 btn-reconnect green-button ff-inter-semi py-2`}
                    type="button"
                    onClick={() => {
                      if (value === "") {
                        setValidate(true);
                        setMessage("Please enter your shop URL");
                      } else {
                        onClickVerify(domain, setLoading, toggle);
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
                <p className="text-gray ff-inter-regular text-center my-3">
                  By continuing, you agree to our{" "}
                  <a
                    href={`${Config.HOME_URL}/privacypolicy`}
                    target={"_blank"}
                    className="text-blue"
                  >
                    Privacy Policy
                  </a>
                </p>
              </Form>
            </div>
            {loading && (
              <div className="position-loader">
                <Loader />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default StepOne;
