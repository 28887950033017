import { MenuItems } from "components/MenuItems";
import Navbar from "components/NavbarDashboard";
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import BillingChart from "components/CircularProgressBar/BillingGradient";
import SetupPaymentModal from "./SetupPaymentModal";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../../../configs/config.json";
import Loader from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentAddedStatus } from "redux/actions";
import BillingPlans from "components/BillingPlans/Subscribed";
import secureLocalStorage from "react-secure-storage";
import { Service } from "services/Service";
// import PaymentNotification from 'components/PaymentNotification'

const stripePromise = loadStripe(Config.STRIPE_KEY);

function BillingOverview() {
  const { userDetails, isFetchUserList, paymentStatus } = useSelector(
    ({ modal }) => modal
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [openSetupModal, setOpenSetupModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [data, setData] = useState({});
  const [apiUsage, setApiUsage] = useState(1234);

  const toggleSetupModal = () => setOpenSetupModal(!openSetupModal);

  useEffect(() => {
    dispatch(getPaymentAddedStatus());
  }, []);

  useEffect(() => {
    setData(userDetails);
    setApiUsage(
      userDetails.monthly_usage &&
        userDetails.monthly_usage
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }, [userDetails]);

  // Call this API when paymentStatus updated and true
  useEffect(() => {
    if (paymentStatus) {
      dispatch(getPaymentAddedStatus());
    }
  }, [paymentStatus]);

  // const handlePage = () => {
  //     if (data && data.payment_method) {
  //         history.push('/billing/payment-methods')
  //     } else {
  //         toggleSetupModal()
  //     }
  // }

  // const handlePage = () => {
  //   const storeUrl = secureLocalStorage.getItem("store_url")
  //   Service.get({
  //     url: `/billing/add_billing/?redirect=false&shop=${storeUrl}`,
  //   })
  //     .then((response) => {
  //       if (response && response.status) {
  //         window.location.href = response.confirmation_url;
  //         // clearInterval(timeInterval)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
    <Elements stripe={stripePromise}>
      <div className="bg-white dashboard-page d-flex flex-column">
        <Helmet>
          <meta charSet="utf-8" />
          <title>PRIYA | Billing</title>
        </Helmet>
        <Navbar />
        {/* <PaymentNotification /> */}
        <div className="d-flex flex-column flex-xl-row project-section flex-grow">
          <div className="d-flex align-items-center align-items-xl-start pr-0 menu-view">
            <div className="d-none d-xl-block">
              <MenuItems />
            </div>
            <span className="d-block d-sm-none ml-3 ff-inter-semi my-2 text-xl ">
              {window.location.href.indexOf("/dashboard") > -1
                ? "Dashboard"
                : window.location.href.indexOf("/billing") > -1
                ? "Billing"
                : window.location.href.indexOf("/settings") > -1
                ? "Settings"
                : window.location.href.indexOf("/logout") > -1
                ? "Logout"
                : ""}
            </span>
          </div>
          <div className="w-100 content-view payment-screen height-bill-settings p-3 p-xl-4 h-full">
            <div className="bg-white payment-card p-3">
              {/* <h3 className="ff-inter-semi text-dark">Billing Overview</h3> */}
              {/* <p className="ff-inter-medium text-gray mb-4">
                      PRIYA is free to use up to 20,000 API calls, then pay as you go using your connected Shopify account. You'll be charged on a monthly basis for all usage.
                      </p> */}
              <BillingPlans />
              {/* {isFetchUserList ? (
                  <div className="mt-5 pt-5 d-flex justify-content-center">
                    <Loader />
                  </div>
                ) : (
                  <Row>
                    <Col md="4">
                      <div className="bg-card d-flex flex-column align-items-center justify-content-between p-4">
                        <div>
                          <BillingChart />
                        </div>
                        <div className="mt-5 text-center">
                          <p className="ff-inter-medium text-gray mb-0">
                            Monthly PRIYA Usage:
                          </p>
                          <p className="ff-inter-medium text-darkgray mb-0 text-28">
                            {apiUsage} API Calls ($
                            {data &&
                              data.total_cost &&
                              data.total_cost.toFixed(2)}
                            )
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="8" className="pl-md-4 mt-5 mt-md-0">
                    </Col>
                  </Row>
                )} */}
            </div>
          </div>
        </div>

        <SetupPaymentModal
          isOpen={openSetupModal}
          toggle={toggleSetupModal}
          type={companyModal}
          paymentAdded={data && data.payment_method}
        />
      </div>
    </Elements>
  );
}

export default BillingOverview;
