import { MenuItems } from 'components/MenuItems'
import Navbar from 'components/NavbarDashboard'
import PaymentNotification from 'components/PaymentNotification'
import React, { useEffect, useState } from 'react'
import { Service } from 'services/Service';
import { openNotification } from 'components/Helper';
import Loader from 'components/Loader'


function BillingHistory() {

    const [fileDetails, setFileDetails] = useState([])
    const [loading, setLoading] = useState(true)

    const BillingHistory = () => {
        Service.get({
            url: `/billing-history/`
        })
            .then(response => {
                if (response && response.status) {
                    setFileDetails(response && response.files)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setFileDetails([])
                }
            }
            )
            .catch(err => {
                console.log(err)
                openNotification('error', 'Oops!', 'Something went wrong. Please try later!')
            })
    }

    useEffect(() => {
        BillingHistory()
    }, [])

    return (
        <div className='bg-white dashboard-page'>
            <Navbar />
            {/* <PaymentNotification /> */}
            <div>
                <div className="d-flex flex-column flex-xl-row project-section">
                    <div className="d-flex align-items-center align-items-xl-start pr-0 menu-view">
                        <div className='d-none d-xl-block'>
                            <MenuItems />
                        </div>
                        <span className='d-block d-sm-none ml-3 ff-inter-semi my-2 text-xl '>{(window.location.href.indexOf('/dashboard')) > -1 ? 'Dashboard' : (window.location.href.indexOf('/billing')) > -1 ? 'Billing' : (window.location.href.indexOf('/settings')) > -1 ? 'Settings' : (window.location.href.indexOf('/logout')) > -1 ? 'Logout' : ''}</span>
                    </div>
                    <div className="w-100 content-view payment-screen h-100vh p-3 p-xl-4">
                        <div className='bg-white payment-card billing-settings p-3'>
                            <div className='settings-screen '>
                                <h3 className='ff-inter-semi text-dark'>Billing History</h3>
                                <p className='ff-inter-medium text-gray text-14'>Showing invoices within the past 12 months</p>
                                {loading ?
                                    <div className='mt-5 text-center'><Loader />
                                    </div> : <>{fileDetails && fileDetails.length === 0 ? <p className='ff-inter-medium text-gray text-14'>No invoices found</p> : <a href={`${fileDetails[0].url}`} target={'_blank'} className='text-blue'>{fileDetails[0].filename}</a>}</>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BillingHistory