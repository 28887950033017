import React from 'react'

const StepTwo = ({ errorMessage, nextPage }) => {


    return (
        <>
            <div className='mx-3 mt-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-center'><div className='d-flex flex-column align-items-center justify-content-between step-success px-2 py-3 px-sm-5'>
                <img src={require('../../assets/img/Priya_AI/logo-error.png')} className="img-fluid mt-3 mb-5 mb-lg-0" />
                <div className='text-center'>
                    <p className='text-dark mb-0 ff-medium'>Connection Failed!</p>
                    <p className='text-gray mb-0 ff-inter-regular'>Please review the errors below and make sure PRIYA is connected properly.</p>
                    <p className='text-red mt-3 ff-inter-regular'>{errorMessage !== '' ? errorMessage : 'Contact support@priya.ai'}</p>
                </div>
                <p className='bottom-0 text-blue ff-inter-regular border-top pt-2 cursor-pointer mt-5 mt-lg-0' onClick={() => nextPage('pageone')}>Try Again</p>
            </div></div>
        </>
    )
}

export default StepTwo;