// import { MenuItems } from "components/MenuItems";
// import Navbar from "components/NavbarDashboard";
// import PaymentNotification from "components/PaymentNotification";
import { Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Service } from "services/Service";
import { openNotification } from "components/Helper";
import { TiTick } from "react-icons/ti";
import secureLocalStorage from "react-secure-storage";

// import Loader from "components/Loader";

function BillingOptions() {
  const [priceDetails, setpriceDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const get_subscribed_plan = () => {
    return secureLocalStorage.getItem("plan_id");
  };

  const GetPricingDetails = () => {
    Service.get({
      url: `/billing/plans/`,
    })
      .then((response) => {
        if (response && response.status) {
          setpriceDetails(response && response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setpriceDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  };

  useEffect(() => {
    GetPricingDetails();
  }, []);

  const updateText = (text) => {
    let updatedText = text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return updatedText;
  };

  async function HandleClick(id) {
    Service.get({
      url: `/billing/plans/subscribe/?plan_id=${id}`,
    })
      .then((response) => {
        if (response && response.status && response.confirmation_url) {
          window.location.href = response?.confirmation_url;
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(
          "error",
          "Oops!",
          "Something went wrong. Please try later!"
        );
      });
  }
  return (
    <div className="">
      <div>
        <h1 className="text-dark text-center heading_text_billing ff-inter-regular">
          Select a plan to get started with PRIYA
        </h1>
        <p>7 Day free trial included</p>
      </div>
      <div className="main_box">
        <div className="grid_box payment-card  billing-settings ">
          {priceDetails.map((el) => {
            return (
              <div
                className={`custom-shadow ${
                  el?.most_popular ? "most_popular_selected" : ""
                } billing_card`}
              >
                {el.most_popular && (
                  <div className="most_popular ff-inter-bold">Most Popular</div>
                )}
                <div className="pkg_name  ff-inter-semi">{el.title}</div>
                <div className="main_price_box">
                  <p className="text-gray dollar_box">$</p>
                  <div className="ff-inter-bold text-dark price_box">
                    {el.price}
                  </div>
                  <div className="mo_section">/mo</div>
                </div>
                {!el?.subscribed && (
                  <div>
                    <div className="margin_box">
                      {el.products_limit && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            Up to {el.products_limit} Products
                          </div>
                        </div>
                      )}
                      {el.price_updating_frequency && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            {el.autonomus_pricing && "Autonomous pricing"}
                          </div>
                        </div>
                      )}
                      {/* {el.price_updating_frequency && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            {updateText(el.price_updating_frequency)} Price
                            Testing
                          </div>
                        </div>
                      )} */}
                      {el.pricing_data_reporting && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">
                            Price Data And Reporting
                          </div>
                        </div>
                      )}
                      {el.api_access && (
                        <div className="ff-inter-medium d-flex text-14">
                          <TiTick className="text-dark" size={20} />
                          <div className="text-gray">Access to PRIYA API</div>
                        </div>
                      )}
                    </div>
                    <button
                      disabled={el?.id === get_subscribed_plan()}
                      onClick={() => {
                        HandleClick(el?.id);
                      }}
                      className="subscribe_btn ff-inter-semi btn-gray"
                      style={{
                        cursor:
                          el?.id === get_subscribed_plan()
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      {el?.id === get_subscribed_plan()
                        ? "Current Plan"
                        : "Update Plan"}
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BillingOptions;
