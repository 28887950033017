import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Config from "../../configs/config.json";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { getProductList, getStarted } from "redux/actions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientBillingSVG from "components/CircularProgressBar/gradientBillingSVG";
import IndexNavbar from "components/Navbars/IndexNavbar";
import { Helmet } from "react-helmet";
import { Service } from "services/Service";
import Loader from "components/Loader";

const Success = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [progress, setProgress] = useState(0);
  const [loginStatus, setLoginStatus] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const code = params.get("token");
  const login = params.get("login");

  useEffect(() => {
    const isAlreadyLoggedIn = secureLocalStorage.getItem("token");
    const isBilled = secureLocalStorage.getItem("is_billed");
    const integrationProcess = secureLocalStorage.getItem(
      "integration_process"
    );

    if (isAlreadyLoggedIn && isBilled) {
      {
        if (integrationProcess === "not_completed") {
          setShowProgress(true);
          CheckStatus();
        } else {
          setLoginStatus(true);
          setTimeout(() => {
            //Change to Revert
            history.push(`/`);
          }, [3000]);
        }
      }
    } else {
      shopifyLogin(code);
      if (login) {
        setLoginStatus(true);
      } else {
        setShowProgress(true);
      }
    }
  }, []);

  useEffect(() => {
    if (showProgress) {
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 2 : 100
        );
      }, 150); // Update the progress every second, adjust as needed

      setTimeout(() => {
        setProgress(100);
        setShowProgress(false);
      }, [9000]);

      return () => clearInterval(interval);
    }
  }, [showProgress]);

  const shopifyLogin = (code) => {
    const params = new FormData();
    params.append("token", code);

    Service.post({
      url: `/shopify/user_detail/`,
      body: params,
      formdata: true,
    })
      .then((response) => {
        if (response && response.status === true) {
          secureLocalStorage.setItem(
            "email",
            response && response.user_data && response.user_data.email
          );
          secureLocalStorage.setItem("verified", true);
          secureLocalStorage.setItem(
            "integration_process",
            response &&
              response.user_data &&
              response.user_data.configuration_status
              ? "completed"
              : "not_completed"
          );
          const currentTime = new Date();
          const twoHoursFromNow = new Date(
            currentTime.getTime() + 2 * 60 * 60 * 1000
          );
          secureLocalStorage.setItem("Expired", twoHoursFromNow.toString());
          setTimeout(() => {
            if (
              response &&
              response.user_data &&
              response.user_data.configuration_status
            ) {
              setLoginStatus(false);
              if (!response?.user_data?.signup_status) {
                history.push("/signup");
              } else {
                secureLocalStorage.setItem("is_loggedin", "done");
                secureLocalStorage.setItem(
                  "token",
                  response &&
                    response.user_data &&
                    response.user_data.access_token
                );
                secureLocalStorage.setItem(
                  "refreshToken",
                  response &&
                    response.user_data &&
                    response.user_data.refresh_token
                );
                if (!response?.user_data?.plan_configured) {
                  history.push("/billing");
                } else {
                  let first_register =  secureLocalStorage.getItem("first_register");
                  console.log("The First Register is ",first_register)
                  if (first_register) {
                    dispatch(getStarted(true));
                  }
                  secureLocalStorage.setItem("is_billed", true);
                }

                history.push(`/`);
              }
            } else {
              CheckStatus();
            }
          }, [3000]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CheckStatus = () => {
    Service.get({
      url: `/shopify/status/`,
    })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDashboard = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    dispatch(getStarted(true));
    secureLocalStorage.setItem("integration_process", "completed");

    setTimeout(() => {
      const params = {
        limit: 12,
        page: 1,
        keyword: "",
      };
      dispatch(getProductList(params));
    }, 3000);

    setTimeout(() => {
      // secureLocalStorage.setItem("initialDashboard", false);
    }, 4000);
  };

  const handleSignup = () => {};

  return (
    <>
      <div className="bg-dark ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>PRIYA | Installation</title>
        </Helmet>
        <div className={`bg-left-auth integration-modal`}>
          <section className="py-0 nav-section">
            <IndexNavbar />
          </section>

          {loginStatus ? (
            <div className="position-desktop">
              <div className="text-center">
                <Loader />
              </div>
              <p className="text-gray font-style ff-light mt-4 text-center text-22">
                Connecting to Dashboard...
              </p>
            </div>
          ) : (
            <>
              {showProgress && progress !== 100 ? (
                <Row className="position-desktop px-3">
                  <Col lg="1"></Col>
                  <Col lg="10" className="px-sm-5">
                    <div className="text-center">
                      <div className="centered-progress-bar d-flex align-items-center justify-content-center mt-md-5">
                        <div style={{ height: "180px", width: "180px" }}>
                          <GradientBillingSVG />
                          <CircularProgressbar
                            value={progress}
                            text={`${progress}%`}
                            strokeWidth={5}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: `url(#hello)`,
                              textColor: "gray",
                            })}
                          />
                        </div>
                      </div>
                      <span className="text-center mt-5">
                        <h3
                          style={{ color: "#d7d7d7" }}
                          className="ff-inter-regular mb-0 mt-5"
                        >
                          Checking Connection Status...
                        </h3>
                      </span>
                    </div>
                  </Col>
                  <Col lg="1"></Col>
                </Row>
              ) : (
                <div className="mx-3 mt-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-column align-items-center justify-content-between step-success px-2 py-3 px-sm-5">
                    <img
                      src={
                        require("../../assets/img/Priya_AI/logo-success.png")
                          .default
                      }
                      className="img-fluid mt-3 mb-5 mb-lg-0"
                    />
                    <div className="text-center">
                      <p className="text-dark mb-0 ff-medium">
                        Connection Successful!
                      </p>
                      <p className="text-gray mb-0 ff-inter-regular">
                        You may now continue on to your Dashboard.
                      </p>
                    </div>
                    <p
                      className="text-blue ff-inter-regular mt-5 mt-lg-0 border-top pt-2 cursor-pointer"
                      onClick={() => handleDashboard()}
                    >
                      <Link
                        to="/"
                        className="text-blue ff-inter-regular border-top cursor-pointer"
                      >
                        Get Started
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}

          {/* {loginStatus === false && <>{progress !== 100 && <Row className='position-desktop px-3'>
                        <Col lg="1">
                        </Col>
                        <Col lg="10" className='px-sm-5'>
                            <div className='text-center'>
                                <div className="centered-progress-bar d-flex align-items-center justify-content-center mt-md-5">
                                    <div style={{ height: "180px", width: "180px" }}>
                                        <GradientBillingSVG />
                                        <CircularProgressbar value={progress} text={`${progress}%`} strokeWidth={5} styles={buildStyles({
                                            strokeLinecap: "butt",
                                            pathColor: `url(#hello)`,
                                            textColor: "gray"
                                        })} />
                                    </div>
                                </div>
                                <span className='text-center mt-5'>
                                    <h3 style={{ color: '#d7d7d7' }} className='ff-inter-regular mb-0 mt-5'>Checking Connection Status...</h3>
                                </span>
                            </div>
                        </Col>
                        <Col lg="1">
                        </Col>
                    </Row>}</>}

                    {status && <>{progress === 100 && <div className='mx-3 mt-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-center'><div className='d-flex flex-column align-items-center justify-content-between step-success px-2 py-3 px-sm-5'>
                        <img src={require('../../assets/img/Priya_AI/logo-success.png')} className="img-fluid mt-3 mb-5 mb-lg-0" />
                        <div className='text-center'>
                            <p className='text-dark mb-0 ff-medium'>Connection Successful!</p>
                            <p className='text-gray mb-0 ff-inter-regular'>You may now continue on to your Dashboard.</p>
                        </div>
                        <p className='text-blue ff-inter-regular mt-5 mt-lg-0 border-top pt-2 cursor-pointer' onClick={() => handleDashboard()}><Link to="/" className='text-blue ff-inter-regular border-top cursor-pointer'>Get Started</Link> </p>
                    </div></div>}</>} */}
        </div>
      </div>
    </>
  );
};

export default Success;
